
const o = {

    authHeader : function(){
    
        const session = JSON.parse(sessionStorage.getItem('session'));
    
        if (session && session.token) {
            return { 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer ' + session.token 
            };
        } else {
            return {};
        }
    },

    setAuthHeader : function(token){

        console.log('setAuthHeader', token);

        sessionStorage.setItem('session', JSON.stringify({token}));
    }
}


export default o;