import axios from "axios";
import { useRef, useState } from "react";
import config from "../../config";
import helperHeader from '../../authHeader.js';
import { Box, Card, CardMedia, Fab } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";


export default function ActionAreaCard(props) {


    const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

    const { object, sx } = props;

    const icon = object.icon && object.icon !== '' ? object.icon : config.placeholder;



    const [image, setImage] = useState(icon) 
    const fileInput = useRef();


    const { authHeader, setAuthHeader } = helperHeader;


    async function onUpload(event){
        try {
            const file = event.target.files[0];

            const formData = new FormData();    
            formData.append("avatar", file);
            
            await axios.post(url + '/' + object.entity + '/' + object.id + '/image', 
                formData, 
                { 
                    headers: { 
                        ...authHeader(),
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            const reader = new FileReader();
            reader.readAsDataURL(file);
        
            reader.onloadend = function(e) {
                setImage( reader.result)
            }.bind(this);
        }
        catch (error) {

            console.log(error);

            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }
    }

    async function removeImage() {
        try {
            const t = await axios.delete(url +  '/' + object.entity + '/' + object.id + '/image', { headers: authHeader() });
        
            setImage('/placeholder.jpg')

            const { meta } = t.data;

            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }        
        }
        catch (error) {
            console.log(error);

            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }
    }

    return (
        <Card sx={{ width: 250, ...sx }}>
            <CardMedia
                component="img"
                height="200"
                image={image}
                alt={object.name}
                sx={{objectFit: "contain" }} 
            />
           
            <Box sx={{p:1, textAlign:'right'}}>
                <Fab sx={{mr:2}} color="primary" aria-label="add" onClick={() => fileInput.current.click()}>
                    <Add />
                </Fab>

                <Fab color="error" aria-label="add" onClick={removeImage}>
                    <Remove />
                </Fab>

                <input
                    ref={fileInput}
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={onUpload}
                />
            </Box>
        </Card>
    );
}