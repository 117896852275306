import { ContactPage, Dvr, EmojiEvents, MilitaryTech, PeopleAlt,  WebStories  } from '@mui/icons-material';
import {strings} from './strings.js'
//http://localhost:5000

const config = {
    language : {
        default : 1,
    },

    server : {
        protocol : 'https',
        host: 'badges.elogos.cloud',
        port: '443',

        // protocol : 'http',
        // host: 'localhost',
        // port: '5000',

        prefix : '/api/test'
    },
    drawer : {
        menu : [
            [   
                {
                    code : 'clients',
                    url : '/clients',
                    icon: <ContactPage />

                },

                {
                    code : 'platforms',
                    url : '/platforms',
                    icon: <WebStories />

                }
               /* {
                    code : 'users',
                    url : '/users',
                    icon: <PeopleAlt />

                }
                */

               
            ],
     

            [
               {
                    code : 'rewards',
                    sub : 'platform',
                    url : '/platforms/:id/rewards',
                    icon: <EmojiEvents />
                },
                {
                    code : 'ranks',
                    sub : 'platform',
                    url : '/platforms/:id/ranks',
                    icon: <MilitaryTech />
                },{
                    code : 'users',
                    sub : 'platform',
                    url : '/platforms/:id/users',
                    icon: <PeopleAlt />
                },
                {
                    code : 'logs',
                    sub : 'platform',
                    url : '/platforms/:id/logs',
                    icon: <Dvr />
                },

                {
                    code : 'rewards',
                    sub : 'user',
                    url : '/users/:id/rewards',
                    icon: <EmojiEvents />
                },
                {
                    code : 'logs',
                    sub : 'user',
                    url : '/users/:id/logs',
                    icon: <Dvr />
                }
            ]
        ]
    },

    typeOptions : [
        {
            id: 0,
            name: strings.custom
        },
        {
            id: 1,
            name: strings.normal
        }
    ],

    // <MenuItem value={"unlimited"}>{strings.unlimited}</MenuItem>
       //unlimited, minutely, hourly, daily, weekly, monthly, yearly

    limitType :[
        {
            value : "unlimited",
            label : strings.unlimited
        },
        {
            value : "minutely",
            label : strings.minutely
        },
        {
            value : "hourly",
            label : strings.hourly
        },
        {
            value : "daily",
            label : strings.daily
        },
        {
            value : "weekly",
            label : strings.weekly
        },
        {
            value : "monthly",
            label : strings.monthly
        },
        {
            value : "yearly",
            label : strings.yearly
        }
    ],

    placeholder:  'https://d35j8npxpr4fe7.cloudfront.net/upload/placeholder.jpg',
    cloneUrlHttp: "https://git-codecommit.eu-south-1.amazonaws.com/v1/repos/",
    emailCertbot: "mict@jogroup.eu" 

}

export default config;
