import { useEffect, useRef, useState } from "react";
import config from '../config';
import { Link as LinkRouter, useNavigate, useParams } from 'react-router-dom';
import { Box, Button,  Dialog,  DialogActions,  DialogContent,  DialogTitle,  FormControlLabel, Grid, IconButton,  InputAdornment, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, Switch, Tab, Tabs, TextField, Toolbar, Typography} from '@mui/material';

import axios from 'axios';
import helperHeader from '../authHeader';
import {strings} from '../strings.js'
import { ArrowBack, Autorenew, Check, Close, Delete, FileCopy, Visibility, VisibilityOff, WebStories } from "@mui/icons-material";


let btn = 'o';

export default function Platform(props) {

    const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;
    
    const { id='0' } = useParams();
    
    const [platform, setPlatform] = useState();
    
    
    const history = useNavigate();

    const [clientsOptions, setClientsOptions] = useState([]);

    const [lock, setLock] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [ openConfim , setOpenConfim] = useState(false);

    //const [valueTab, setValueTab] = useState(0);

    
    const { authHeader, setAuthHeader } = helperHeader;

    
    const loadClientData = async () => {


        try {
            const response =  await axios.get(url + '/clients', {
                params: {
                    limit: 100,
                    sort: 'name'
                },
                headers: authHeader()
    
            });

            const {data, meta} = response.data;

        
            setClientsOptions([{id: 0, name:  `-- ${strings.select} --`}, ...data]);

			if (meta && meta.token) {
				setAuthHeader(meta.token);
			}

        } catch (error) {
            console.log(error);

			alert(error.message);

			if (error.response && error.response.status && error.response.status === 401) {

				sessionStorage.clear();
				return window.location.assign('/');
			}

			const { meta } = error.response.data;
			if (meta && meta.token) {
				setAuthHeader(meta.token);
			}
        }
	}

   
    const loadData = async () => {
       
        try {

            const response = await axios.get(url + '/platforms/' + id, { headers: authHeader() })
            
            if (response) {
                const { platform, meta } = response.data;
                console.log(platform);
                
                setPlatform(platform);

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            }
        }
		catch (error) {
            console.log(error);
            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }
    }

    const inputPublicKey = useRef();
    const inputSecretKey = useRef();



    useEffect( () =>{

        if (id !== '0') {
            props.setSubMenu({
                sub : 'platform',
                id 
            });

            loadData();
        }
        else {

            loadClientData();
      
            setPlatform({
                id : 0,
                client_id: 0,
                name : '',
                url: "",
                email: "",
                status : 1
            });
        }
    }, 
    [])


    // const onAction  = async (params) => {
    //     setLock(true);

    //     setPlatform( { ...platform, ...params })

    //     if(platform && platform.id){
           
    //         try {
           
    //             let r = await axios.patch(url + `/platforms/${platform.id}`, {
    //                 ...params
    //             }, { headers: authHeader() })

    //             const { meta } = r.data;

    //             if (meta && meta.token) {
    //                 setAuthHeader(meta.token);
    //             }

    //         }
	// 		catch (error) {

	// 			console.log(error);

    //             if ( error.response ) {

    //                 //console.log(error.response.data.message);
    //                 alert( error.response.data.message )
    //             }

                

    //             //let msg = error.message;

    //             // if (error.response && error.response.status && error.response.status === 401) {
    //             //     alert(msg);
    //             //     sessionStorage.clear();
    //             //     return window.location.assign('/');
    //             // }

    //             // if (error.request && error.request.response) {
    //             //     const { errors, meta } = JSON.parse(error.request.response)

    //             //     if (meta && meta.token) {
    //             //         setAuthHeader(meta.token);
    //             //     }

    //             //     for (const error of errors) {
    //             //         msg += `\n${error.value}: ${error.msg}`;
    //             //     }
    //             // }

    //             setLock(false);
    //             //return alert(msg);
    //         }
    //     }

    //     setLock(false);

    // }

    function ConfirmDialog( props ){

        const {
            title = strings.confirm,
            body = strings.deleteConfirm
        } = props;
    

        return (

           <Dialog
                open={openConfim}
                onClose={(event, reason) => { if (reason && reason === "backdropClick") return; setOpenConfim(false) }}
                aria-labelledby="draggable-dialog-title"
                fullWidth={true}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {body}
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="error" endIcon={<Close />} onClick={ e => setOpenConfim(false)}>{strings.cancel}</Button>
                    <Button variant="contained" color="primary" endIcon={<Check />} onClick={ onDelete }>{strings.delete}</Button>
                </DialogActions>
            </Dialog>
        )
    } 

    const onDelete = async (e) => {

        try {
            const {data, meta } = await axios.delete(url + `/platforms/${platform.id}`, {
                headers: authHeader()
            })

            console.log(data, meta); 


        } catch (error) {
            console.log(error);
        }

        setOpenConfim(false);
    }

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        setLock(true);

        if(platform && platform.id){
           
            try {
           
                let r = await axios.patch(url + `/platforms/${platform.id}`, {
                    ...platform,
                }, { headers: authHeader() })

                const { meta } = r.data;

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            }
			catch (error) {
				console.log(error);
                let msg = error.message;

                if (error.response && error.response.status && error.response.status === 401) {
                    alert(msg);
                    sessionStorage.clear();
                    return window.location.assign('/');
                }


                if ( error.response ) {
                    setLock(false);
                    return alert( error.response.data.message )
                }


                if (error.request && error.request.response) {
                    const { errors, meta } = JSON.parse(error.request.response)

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                    for (const error of errors) {
                        msg += `\n${error.value}: ${error.msg}`;
                    }
                }

                setLock(false);
                return alert(msg);
            }
        }else{

            try {
                const { data } = await axios.post(url + '/platforms', {
                    ...platform
                }, { headers: authHeader() })

                setPlatform({...data.data});

                const platform_id = data.data.id;


                const response = await axios.get(url + '/reward/default', {
					headers: authHeader()
				});

				if (response){

					const { data, meta } = response.data;

                    console.log(data);

                    for (let index = 0; index < data.length; index++) {
                        const defaultReward = data[index];

                        const { data: reward, meta  } = await axios.post(url + '/reward' , {
                            name : defaultReward.name,
                            description : defaultReward.description,
                            type : 0,
                            platform_id 
                        }, { headers: authHeader() })

                        const {id : reward_id} = reward;

                        if (meta && meta.token) {
                            setAuthHeader(meta.token);
                        }

                        const { meta : metaRule } = await axios.post(url + '/reward/' + reward_id + '/rule', {
                            event : defaultReward.event,
                            count : defaultReward.count,
                            url : defaultReward.url,
                            limit : defaultReward.limit,
                            limit_type : defaultReward.limit_type, 
                            energy : defaultReward.energy, 
                            points : defaultReward.points, 
                            maximum_earnings : defaultReward.maximum_earnings
                        }, { headers: authHeader() }) 


                        if (metaRule && metaRule.token) {
                            setAuthHeader(metaRule.token);
                        }
                    }
                }

            }
            catch (error) {
                console.log(error);
                let msg = error.message;

                if (error.response && error.response.status && error.response.status === 401) {
                    alert(msg);
                    sessionStorage.clear();
                    return window.location.assign('/');
                }

                if (error.request && error.request.response) {
                    const { errors, meta } = JSON.parse(error.request.response)

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                    // for (const error of errors) {
                        // msg += `\n${error.value}: ${error.msg}`;
                    // }
                }

                setLock(false);
                return alert(msg);
            }

           
        }
        setLock(false);

        if (btn === 'o') {
            history('/platforms');
        }
        
    }


    const regenerateSecretKey = async e => {

      
        if(platform && platform.id){
            
            let r = await axios.patch(url + `/platforms/${platform.id}/generateSecretKey`, {}, { headers: authHeader() })

            const { meta, data } = r.data;

            //console.log(data);

            inputSecretKey.current.value = data.secretKey;

            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }

    }

    return (

        <>
            <ConfirmDialog  />
            <Box sx={{ display: 'flex', height: '90%' }}>

                <Box sx={{ flexGrow: 1 }}>

                    <Box sx={{ height: '10%' }}>

                        <Toolbar>
                            <WebStories sx={{fontSize: 40, mr: 2}} />

                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                {strings.platform} - {platform ? (platform.id ? `${strings.edit} - ${platform.name}` : strings.addNew ) : <Skeleton variant="text" />}
                            </Typography>

                            <Button component={LinkRouter} to={`/platforms`} startIcon={<ArrowBack />} variant="contained" color="primary">{strings.back}</Button>
                        </Toolbar>
                    </Box>

                    <Box sx={{ p: 5, height: '100%', overflowY: 'auto' }}>
                        {platform ?
                            <Box component={'form'} onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid container spacing={4} >

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                                            {platform.id !== 0 ? 
                                                
                                                <TextField
                                                    
                                                    label={strings.client}
                                                    disabled
                                                    fullWidth
                                                    variant="standard"
                                                    value={platform.clients.name}
                                                    sx={{ mb: 4 }}
                                                />
                                                : 
                                                
                                                <TextField
                                                    select
                                                    required
                                                    id="client"
                                                    name="client"
                                                    label={strings.client}
                                                    fullWidth
                                                    
                                                    variant="standard"
                                                    value={platform.client_id ?? 0}
                                                    onChange={event => setPlatform({ ...platform, client_id: event.target.value }) }
                                                    
                                                    sx={{ mb: 4 }}
                                                >
                                                    {clientsOptions.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            }
                                            
                                            
                                            <TextField
                                                required
                                                name="name"
                                                label={strings.name}
                                                fullWidth
                                                variant="standard"
                                                value={platform.name}
                                                onChange={ event => setPlatform( { ...platform, name: event.target.value }) }
                                                //onChange={ e => { onAction( {name: e.target.value}) } }
                                                sx={{ mb: 4 }}
                                            />

                                            <TextField
                                                required
                                                name="url"
                                                label={strings.url}
                                                fullWidth
                                                variant="standard"
                                                value={platform.url}
                                                onChange={event => { setPlatform({ ...platform, url: event.target.value }) }}
                                                sx={{ mb: 4 }}
                                            />

                                                
                                            <FormControlLabel control={<Switch checked={platform.status} onChange={event => { setPlatform({ ...platform, status: event.target.checked })}} defaultChecked />} label={platform.status ? strings.enabled : strings.disabled} />

                                            
                                        </Box>
                                    </Grid>


                                    {platform.id !== 0 ?         

                                        <Grid item xs={12} md={6}>
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    rea
                                                    autoComplete = "current-publicKey"
                                                    type="text"
                                                    label={strings.publicKey}
                                                    value={platform.publicKey}
                                                    inputRef={inputPublicKey}

                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                            <IconButton onClick={ () => { navigator.clipboard.writeText(inputPublicKey.current.value)} } edge="end">
                                                                <FileCopy />
                                                            </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{ mb: 4 }}
                                                    
                                                />

                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    
                                                    autoComplete = "current-secretKey"
                                                    type={showPassword ? "text" : "password"}

                                                    label={strings.secretKey}
                                                    value={platform.secretKey}
                                                
                                                    inputRef={inputSecretKey}

                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton sx={{mr: .1}} onClick={ () => setShowPassword((prev)=> !prev)} edge="end">
                                                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                                </IconButton>
                                                                <IconButton sx={{mr: .1}} onClick={ () => { navigator.clipboard.writeText(inputSecretKey.current.value)} } edge="end">
                                                                    <FileCopy />
                                                                </IconButton>
                                                                <IconButton  onClick={ regenerateSecretKey } edge="end">
                                                                    <Autorenew />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{ mb: 4 }}
                                                    
                                                />

                                            </Box>    
                                        </Grid>
                                    : 
                                        <></>
                                    }

                                </Grid>

                                <Box sx={{ mt: 5, display: 'flex', justifyContent: 'space-between', width: '100%' }} >

                                    <Box sx={{ mt: 5, display: 'flex', justifyContent: 'flex-start', width: '100%' }} >
                                        <Button disabled={lock} type='submit' onClick={e => btn = 'a'} sx={{ mr: 2 }} endIcon={<Check />} variant="contained" color="warning">{strings.apply}</Button>
                                        <Button disabled={lock} type='submit' onClick={e => btn = 'o'} endIcon={<Check />} variant="contained" color="primary">{strings.ok}</Button>
                                    </Box>

                                    { platform.id !== 0 &&  
                                        <Box sx={{ mt: 5, display: 'flex', justifyContent: 'flex-end', width: '100%' }} >                                 
                                            <Button disabled={lock} type='button' onClick={ e => setOpenConfim(true) } endIcon={<Delete />} variant="contained" color="error">{strings.delete}</Button>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            :
                            <Skeleton variant="text" />
                        }
                    </Box>
                </Box>
            </Box>
        </>            
    )


}

