import { useEffect, useState } from "react";
import config from '../config';
import { Link as LinkRouter, useNavigate, useParams } from 'react-router-dom';
import {  Box, Button,  FormControlLabel,  Grid, IconButton,  InputAdornment, Skeleton, Switch, TextField, Toolbar, Typography} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import axios from 'axios';
import helperHeader from '../authHeader';
import {strings} from '../strings.js'
import { ContactPage } from "@mui/icons-material";


export default function Client(props) {

    const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;
    
    const { id='0' } = useParams();
    const [client, setClient] = useState();
    const [btn, setBtn] = useState(null);
    const history = useNavigate();

    const [lock, setLock] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
   
    
    const handleSubmit = async (e) => {
        
        e.preventDefault();

        const { authHeader, setAuthHeader } = helperHeader;

        setLock(true);

        if(client && client.id){
            
            try {
                //patch
                let r = await axios.patch(url + `/clients/${client.id}`, {
                    ...client,
                }, { headers: authHeader() })

                const { meta } = r.data;

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            }
			catch (error) {
				console.log(error);
                let msg = error.message;

                if (error.response && error.response.status && error.response.status === 401) {
                    alert(msg);
                    sessionStorage.clear();
                    return window.location.assign('/');
                }

                if (error.request && error.request.response) {
                    const { errors, meta } = JSON.parse(error.request.response)

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                    for (const error of errors) {
                        msg += `\n${error.value}: ${error.msg}`;
                    }
                }

                setLock(false);
                return alert(msg);
            }
        }else{

            try {
                const { data } = await axios.post(url + '/clients', {
                    ...client
                }, { headers: authHeader() })

                console.log(data);
                setClient({ ...client, id: data.data.id });

                const { meta } = data;

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            }
            catch (error) {
                console.log(error);
                let msg = error.message;

                if (error.response && error.response.status && error.response.status === 401) {
                    alert(msg);
                    sessionStorage.clear();
                    return window.location.assign('/');
                }

                if (error.request && error.request.response) {
                    const { meta } = JSON.parse(error.request.response)

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                    // for (const error of errors) {
                        // msg += `\n${error.value}: ${error.msg}`;
                    // }
                }

                setLock(false);
                return alert(msg);
            }

           
        }
        setLock(false);
        if (btn === 'o') {
            history('/clients');
        }
        
    }




    useEffect( () =>{
        
        const { authHeader, setAuthHeader } = helperHeader;

        const loadData = async () => {
       
            try {
                const response = await axios.get(url + '/clients/' + id, { headers: authHeader() })
                if (response) {
                    const { user_platform, meta } = response.data;
    
                    //console.log(user_platform);
                    
                    setClient(user_platform);
    
                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }
    
                }
            }
            catch (error) {
                console.log(error);
                alert(error.message);
    
                if (error.response && error.response.status && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.assign('/');
                }
    
                const { meta } = error.response.data;
                if (meta && meta.token) {
                    await setAuthHeader(meta.token);
                }
            }
        }

        
        if (id !== '0') {
            loadData();
        }
        else {
      
            setClient({
                name : '',
                url: "",
                email: "",
                status : 1
            });
        }

        console.count('useEffect');

    }, [url, id])

    return (

        <Box sx={{ display: 'flex', height: '90%' }}>

                <Box sx={{ flexGrow: 1 }}>

                    <Box sx={{ height: '10%' }}>

                        <Toolbar>
                            <ContactPage sx={{fontSize: 40, mr: 2}} />

                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                {strings.client} - {client ? (client.id ? `${strings.edit} - ${client.name}` : strings.addNew) : <Skeleton variant="text" />}
                            </Typography>

                            <Button component={LinkRouter} to={`/clients`} startIcon={<ArrowBackIcon />} variant="contained" color="primary">{strings.back}</Button>
                        </Toolbar>
                    </Box>

                    <Box sx={{ p: 5, height: '100%', overflowY: 'auto' }}>
                        {client ?
                            <Box component={'form'} onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid container spacing={0} >
                                    <Grid item xs={12} md={8}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                                           
                                            <TextField
                                                required
                                                name="name"
                                                label={strings.name}
                                                fullWidth
                                                //autoComplete="given-name"
                                                variant="standard"
                                                value={client.name}
                                                onChange={event => { setClient( { ...client, name: event.target.value }) }}
                                                sx={{ mb: 4 }}
                                            />

                                            <TextField
                                                required
                                                name="email"
                                                type="email" 
                                                label={strings.email}
                                                fullWidth
                                                //autoComplete="given-name"
                                                variant="standard"
                                                value={client.email}
                                                onChange={event => { setClient({ ...client, email: event.target.value }) }}
                                                sx={{ mb: 4 }}
                                            />

                                            <TextField
                                                fullWidth
                                                required={client.id !== undefined ? false : true}
                                                variant="standard"
                                                name= "password"
                                                //autoComplete = "current-password"
                                                type={showPassword ? "text" : "password"}
                                                label={strings.password}
                                                value={client.password}
                                                onChange={event => { console.log(event.target.value); setClient({ ...client, password: event.target.value }) }}
                                                InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                    <IconButton onClick={()=> setShowPassword((prev)=> !prev)} edge="end">
                                                        {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                                    </IconButton>
                                                    </InputAdornment>
                                                ),
                                                }}
                                                sx={{ mb: 4 }}
                                                
                                            />

                                                
                                            <FormControlLabel 
                                                control={<Switch checked={client.status ? true : false} 
                                                onChange={event => setClient({ ...client, status: event.target.checked })}  />} label={client.status ? strings.enabled : strings.disabled} />

                                            
                                        </Box>
                                    </Grid>

                                   
                                    <Grid item xs={12} sx={{mt:3}}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >

                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                <Button disabled={lock} type='submit' onClick={e => setBtn('o')}  sx={{ mr: 2 }} variant="contained" color="primary">{strings.ok}</Button>
												<Button disabled={lock} type='submit' onClick={e => setBtn('a')} endIcon={<CheckIcon />} variant="contained" color="warning">{strings.apply}</Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

							:

                            <Skeleton variant="text" />
                        }
                    </Box>
                </Box>
            </Box>
    )


}

