import logo from './logo.svg';
import './App.css';

import { Box, createTheme, ThemeProvider } from '@mui/material';
import BrowserThemeContext from './BrowserThemeContext'
import { teal } from '@mui/material/colors';
import Dashboard from './components/Dashboard';
import SignIn from './components/SignIn';

function App() {

	//const [browserTheme, setBrowserTheme] = useBrowserThemeDetector()

	const browserTheme = window.matchMedia("(prefers-color-scheme: light)").matches ? 'light' : 'dark';

	const themaMode  = sessionStorage.getItem('themaMode') ?? browserTheme;

	const appTheme = createTheme({
		palette: {
			mode: `${themaMode}`,

			primary: {
				main: teal[500]
			},

			secondary: {
				main: teal[500]
			}
		},
	});

	const session = sessionStorage.getItem('session');

	console.log(session);

	return (
		<BrowserThemeContext.Provider value={browserTheme}>
			<ThemeProvider theme={appTheme}>
				{ session ? <Dashboard /> : <SignIn /> } 
			</ThemeProvider>
		</BrowserThemeContext.Provider>
		
	);
}

export default App
