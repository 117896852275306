import { useEffect, useState } from "react";
import config from '../config';
import { Link as LinkRouter, useNavigate, useParams } from 'react-router-dom';
import { Box, Button,  Divider,  Grid, IconButton,  InputAdornment, MenuItem, Paper, Skeleton, Tab, Tabs, TextField, Toolbar, Typography} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import axios from 'axios';
import helperHeader from '../authHeader';
import {strings} from '../strings.js'
import { Directions, InsertLink, PeopleAlt } from "@mui/icons-material";

//import PropTypes from 'prop-types';
import TabPanel from "./tools/TabPanel";

import DataGridBages from "./sub/DataGridBages";
import DataGridUserLogs from "./sub/DataGridUserLogs";
import DataGridUserRewards from "./sub/DataGridUserRewards";





export default function User(props) {

    const { id='0' } = useParams();
    const [user, setUser] = useState();

    const [btn, setBtn] = useState(null);
    const history = useNavigate();

    const [ranks, setRanks] = useState([]);
    
    const [lock, setLock] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [valueTab, setValueTab] = useState(0);

    const { authHeader, setAuthHeader } = helperHeader;

    const handleSubmit = async (e) => {
        
        e.preventDefault();

        setLock(true);

        const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

        if(user && user.id){
            const d = { ...user };
            delete d.email;
            
            try {
                //patch
                const r = await axios.patch(url + `/users/${user.id}`, {
                    ...d
                }, { headers: authHeader() })

                const { meta } = r.data;

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            }
			catch (error) {
				console.log(error);
                let msg = error.message;

                if (error.response && error.response.status && error.response.status === 401) {
                    alert(msg);
                    sessionStorage.clear();
                    return window.location.assign('/');
                }

                if (error.request && error.request.response) {
                    const { errors, meta } = JSON.parse(error.request.response)

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                    for (const error of errors) {
                        msg += `\n${error.value}: ${error.msg}`;
                    }
                }

                setLock(false);
                return alert(msg);
            }
        }else{

            try {
                const { data } = await axios.post(url + '/users', {
                    ...user
                }, { headers: authHeader() })

                
                setUser({ ...user, id: data.data.id });

                const { meta } = data;

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            }
            catch (error) {

                console.log(error);
                
                let msg = error.message;

                if (error.response && error.response.status && error.response.status === 401) {
                    alert(msg);
                    sessionStorage.clear();
                    return window.location.assign('/');
                }

                if (error.request && error.request.response) {
                    const { meta } = JSON.parse(error.request.response)

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                    // for (const error of errors) {
                        // msg += `\n${error.value}: ${error.msg}`;
                    // }
                }

                setLock(false);
                return alert(msg);
            }

           
        }
        setLock(false);
        if (btn === 'o') {
            history('/users');
        }   
    }




    useEffect( () =>{

        const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

        const { authHeader, setAuthHeader } = helperHeader;

        const loadRankData = async () => {

            try {
                const response =  await axios.get(url + '/ranks', {
                    params: {
                        limit: 100,
                        sort: 'name'
                    },
                    headers: authHeader()
        
                });
    
                const {data, meta} = response.data;
    
                setRanks( [{id: 0, name:  `-- ${strings.select} --`}, ...data]);
    
                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
    
            } catch (error) {
                console.log(error);
    
                alert(error.message);
    
                if (error.response && error.response.status && error.response.status === 401) {
    
                    sessionStorage.clear();
                    return window.location.assign('/');
                }
    
                const { meta } = error.response.data;
                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
            }
        }


        const loadData = async () => {

            props.setSubMenu({
                sub : 'user',
                id 
            });

       
            try {
                const response = await axios.get(url + '/users/' + id, { headers: authHeader() })

                if (response) {
                    const { data, meta } = response.data;
    
                    setUser(data);
         
                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }
    
                }
            }
            catch (error) {

                console.log(error);
                alert(error.message);
    
                if (error.response && error.response.status && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.assign('/');
                }
    
                const { meta } = error.response.data;
                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
            }
        }

        
        if (id !== '0') { //edit
            loadRankData();
            loadData();
        }

        //console.count('useEffect')
    }, [id])

    return (

        <Box sx={{ display: 'flex', height: '90%' }}>

            <Box sx={{ flexGrow: 1 }}>

                <Box sx={{ height: '10%' }}>

                    <Toolbar>
                        <PeopleAlt sx={{fontSize: 40, mr: 2}} />

                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {strings.user} - {user ? (user.id ? `${strings.edit} - ${user.firstname} ${user.lastname}` : strings.addNew ) : <Skeleton variant="text" />}
                        </Typography>

                        <Button component={LinkRouter} to={`/users`} startIcon={<ArrowBackIcon />} variant="contained" color="primary">{strings.back}</Button>
                    </Toolbar>
                </Box>

                <Box sx={{ p: 5, height: '100%', overflowY: 'auto' }}>
                    {user ?
                        <Box component={'form'} onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} md={7}>
                                    
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 4 }}>
                                            <TextField
                                                
                                                name="platform"
                                                labelId="select-platform-label"
                                                id="platform-select"
                                                label={strings.platforms}
                                                disabled
                                                variant="standard"
                                                value={(user.platform.id + ' - ' + user.platform.name)?? 0}
                                                onChange={event => setUser({ ...user, platform_id: event.target.value }) }

                                                sx={{ width: '96%',  minWidth: '200px', mr: 5  }}
                                            />

                                            
                                            <IconButton size="large" color="primary" aria-label="directions" component={LinkRouter} to={`/platforms/${user.platform.id}`} >
                                                <InsertLink />
                                            </IconButton>
                                        </Box>


                                        <TextField
                                            required
                                            name="firstname"
                                            label={strings.firstName}
                                            fullWidth
                                            autoComplete="given-name"
                                            variant="standard"
                                            value={user.firstname}
                                            onChange={event => { setUser( { ...user, firstname: event.target.value }) }}
                                            sx={{ mb: 4 }}
                                        />

                                        <TextField
                                            required
                                            name="lastname"
                                            label={strings.lastName}
                                            fullWidth
                                            autoComplete="given-name"
                                            variant="standard"
                                            value={user.lastname}
                                            onChange={event => { setUser( { ...user, lastName: event.target.value }) }}
                                            sx={{ mb: 4 }}
                                        />

                                        
                                        <TextField
                                            required
                                            name="birthday"
                                            label={strings.birthday}
                                            fullWidth
                                            type= "date"
                                            autoComplete="given-name"
                                            variant="standard"
                                            value={user.birthday}
                                            defaultValue={ new Date().toISOString().substring(0, 10)}
                                            onChange={event => { setUser( { ...user, birthday: event.target.value }) }}
                                            sx={{ mb: 4 }}
                                        />


                                        <TextField
                                            required
                                            name="email"
                                            type="email" 
                                            disabled
                                            label={strings.email}
                                            fullWidth
                                            autoComplete="given-name"
                                            variant="standard"
                                            value={user.email}
                                            onChange={event => { setUser({ ...user, email: event.target.value }) }}
                                            sx={{ mb: 4 }}
                                        />


                                        <TextField
                                            fullWidth
                                            
                                            variant="standard"
                                            name= "password"
                                            autoComplete = "current-password"
                                            type={showPassword ? "text" : "password"}
                                            label={strings.password}
                                            value={user.password}
                                            onChange={event => { console.log(event.target.value); setUser({ ...user, password: event.target.value }) }}
                                            InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                <IconButton onClick={()=> setShowPassword((prev)=> !prev)} edge="end">
                                                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                                </IconButton>
                                                </InputAdornment>
                                            ),
                                            }}
                                            sx={{ mb: 4 }}
                                            
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={5}>

                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        
                                        <Grid container spacing={3} >

                                            <Grid item xs={6} >
                                                <TextField
                                                    
                                                    name="totPoints"
                                                    labelId="select-platform-label"
                                                    id="platform-select"
                                                    type="number"
                                                    label={strings.totPoints}
                                                    
                                                    variant="standard"
                                                    value={user.totPoints ?? 0}
                                                    onChange={event => setUser({ ...user, totPoints: event.target.value }) }

                                                    sx={{ mb: 4, minWidth: '200px', mr: 5 }}

                                                />

                                                <TextField
                                                    
                                                    name="energy"
                                                    labelId="select-platform-label"
                                                    id="platform-select"
                                                    type="number"
                                                    label={strings.energy}
                                                    variant="standard"
                                                    value={user.energy ?? 0}
                                                    onChange={event => setUser({ ...user, energy: event.target.value }) }
                                                    sx={{ mb: 4, minWidth: '200px', mr: 5 }}

                                                />

                                            </Grid>

                                            <Grid item xs={6} >
                                                
                                                <TextField
                                                
                                                    name="points"
                                                    labelId="select-platform-label"
                                                    id="platform-select"
                                                    type="number"
                                                    label={strings.points}
                                                    variant="standard"
                                                    value={user.points ?? 0}
                                                    onChange={event => setUser({ ...user, points: event.target.value }) }
                                                    sx={{ mb: 4, minWidth: '200px', mr: 5 }}

                                                />
                                            

                                                <TextField
                                                    select
                                                    required
                                                    name="rank"
                                                    label={strings.rank}
                                                    fullWidth
                                                    
                                                    variant="standard"
                                                    value={(user.rank && user.rank.id) ?? 0}
                                                    onChange={event => setUser( user => ({ ...user, rank_id: event.target.value })) }
                                                    
                                                    sx={{ mb: 4 }}
                                                >
                                                    {ranks.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                        </Grid>

                                    </Box>

                                </Grid>

                                
                                <Grid item xs={12} sx={{mt:3}}>
                                    

                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                                        <Button disabled={lock} type='submit' onClick={e => setBtn('o')}  sx={{ mr: 2 }} variant="contained" color="primary">{strings.ok}</Button>
                                        <Button disabled={lock} type='submit' onClick={e => setBtn('a')} endIcon={<CheckIcon />} variant="contained" color="warning">{strings.apply}</Button>
                                    </Box>
                                    
                                </Grid>

                            </Grid>
                        </Box>

                        :

                        <Skeleton variant="text" />
                    }
                </Box>
                
            </Box>
        </Box>
    )


}

