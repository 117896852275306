import config from '../config';
import * as React from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Link as LinkRouter, Route, Routes } from 'react-router-dom';

import Platforms from './Platforms';
import Settings from './Settings';
import Clients from './Clients';
import Client from './Client';

import { Link, Stack } from '@mui/material';
import {strings} from '../strings.js'
import useBrowserLanguageDetector from '../hooks/useBrowserLanguageDetector'
import Platform from './Platform';
import Users from './Users';
import User from './User';
import DataGridPlatformRewards from './sub/DataGridPlatformRewards';
import DataGridPlatformRanks from './sub/DataGridPlatformRanks';
import DataGridUserRewards from './sub/DataGridUserRewards';
import DataGridUserLogs from './sub/DataGridUserLogs';
import DataGridPlatformUsers from './sub/DataGridPlatformUsers';
import DataGridPlatformLogs from './sub/DataGridPlatformLogs';
import { ExitToApp, Settings as SettingsIcon } from '@mui/icons-material';

const drawerWidth = 190

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' }) (
	({ theme, open }) => ({
		flexGrow: 1,
		alignContent: 'stretch',
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: `-${drawerWidth}px`,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}),
);

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(3),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));


function Dashboard() {
    const browserLanguage = useBrowserLanguageDetector()
	strings.setLanguage(browserLanguage)

	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	
	const [subMenu, setSubMenu] = React.useState({});

	const [filter, setFilter] = React.useState('');

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};


	const onExit = () => {
		sessionStorage.clear();
		window.location.assign('/');
	}

	
	let lastDivider = false;

    return (
		<Box sx={{ display: 'flex', justifyContent: 'stretch', height: '100%' }}>
            <CssBaseline/>

            <AppBar position="fixed" open={open}>
				<Toolbar>

					<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                    	<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center',  width: '100%' }} >
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								sx={{ mr: 2, ...(open && { display: 'none' }) }}
							>
								<MenuIcon />
							</IconButton>

							<SettingsBackupRestoreIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

							<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
								Game badges
							</Typography>
						</Box>

						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }} >
							<Search>
								<SearchIconWrapper>
									<SearchIcon />
								</SearchIconWrapper>
								<StyledInputBase
									placeholder={strings.search}
									inputProps={{ 'aria-label': 'search' }}
									value={filter}
									onChange={(event) => {
										setFilter(event.target.value)
									}}
								/>
							</Search>
						</Box>

						<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }} >
							<Stack direction="row" spacing={1}>
								<IconButton title={strings.settings} color="inherit" aria-label="Settings"  component={LinkRouter} to={`/settings`} >
									<SettingsIcon />
								</IconButton>
								<IconButton color="inherit" aria-label="ExitToApp" onClick={onExit} >
									<ExitToApp title={strings.logout} />
								</IconButton>
							</Stack>
						</Box>
					</Box>

				</Toolbar>
			</AppBar>

			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
			>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />

				{config.drawer.menu.map((list, index) => (
					<>
					
					{lastDivider = false}

					<React.Fragment key={index}>
						<List  >
							{list.map((item, indexItem) => (
								<ListItem key={`list-${indexItem}`} disablePadding>
									
									{ item.sub && item.sub === subMenu.sub &&  
										<ListItemButton component={LinkRouter} to={`${item.url.replace(':id', subMenu.id)}`}  >
											<ListItemIcon>
												{item.icon} {lastDivider = true}
											</ListItemIcon>
											<ListItemText primary={strings[item.code]} />
										</ListItemButton>
									}

									{ (item.url && item.sub === undefined) && 
										<ListItemButton component={LinkRouter} to={`${item.url}`}  >
											<ListItemIcon>
												{item.icon} {lastDivider = true}
											</ListItemIcon>
											<ListItemText primary={strings[item.code]} />
										</ListItemButton>
									}

									{item.externalUrl &&  
										<ListItemButton component={Link} href={`${item.externalUrl}`} target={'_blank'}  >
											<ListItemIcon>
												{item.icon} {lastDivider = true}
											</ListItemIcon>
											<ListItemText primary={item.name} />
										</ListItemButton>
									}

									{item.action &&  
										<ListItemButton onClick={item.action} >
											<ListItemIcon>
												{item.icon} {lastDivider = true}
											</ListItemIcon> 
											<ListItemText primary={strings[item.code]} />
										</ListItemButton>
									}
								</ListItem>
							))}
						</List>

						{lastDivider && <Divider />}
						
					</React.Fragment>
					</>
				))}

			</Drawer>

			<Main sx={{ height: '94vh', width: '100%' }} open={open}>
				<DrawerHeader />
				<Routes>
					<Route path="/platforms" element={<Platforms setSubMenu={setSubMenu} filter={filter}/>} />
					<Route path="/platforms/:id" element={<Platform setSubMenu={setSubMenu} />} />	
					<Route path="/platforms/:id/users" element={<DataGridPlatformUsers filter={filter} setSubMenu={setSubMenu} />} />	
					<Route path="/platforms/:id/rewards" element={<DataGridPlatformRewards filter={filter} setSubMenu={setSubMenu} />} />	
					<Route path="/platforms/:id/ranks" element={<DataGridPlatformRanks filter={filter} setSubMenu={setSubMenu} />} />	
					<Route path="/platforms/:id/logs" element={<DataGridPlatformLogs filter={filter} setSubMenu={setSubMenu} />} />	
					
					<Route path="/clients" element={<Clients setSubMenu={setSubMenu} /> } />
					<Route path="/clients/:id" element={<Client setSubMenu={setSubMenu} /> } />				

					<Route path="/users" element={<Users setSubMenu={setSubMenu} /> } />
					<Route path="/users/:id" element={<User setSubMenu={setSubMenu}/> } />				
					<Route path="/users/:id/rewards" element={<DataGridUserRewards setSubMenu={setSubMenu} />} />	
					<Route path="/users/:id/logs" element={<DataGridUserLogs setSubMenu={setSubMenu} />} />							

					<Route path="/settings" element={<Settings/>} />
					<Route path="/*" element={<Typography paragraph>{strings.pageNotFound}</Typography>} />
				</Routes>
			</Main>
        </Box>       

    );
}

export default Dashboard;