import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import config from "../../config.js";
import helperHeader from '../../authHeader.js';
import { strings, getDataGridLocale } from '../../strings.js'
import {  Chip, Link, Skeleton, Toolbar } from "@mui/material";
import {  Bolt, MonetizationOnOutlined,  Star } from "@mui/icons-material";
import { Link as LinkRouter, useParams } from "react-router-dom";

const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

let rowData = null;

export default function DataGridPlatformUsers(props) {

    const { id='0' } = useParams();

    const {filter} = props;

    const platform = {id}
    const [title, setTitle] = useState(null);

    const [rows, setRows] = useState(undefined);


    const columns = useMemo(() => [
		{
			field: 'id',
			headerName: strings.id,
			width: 90
		},
		
		{
			field: 'firstname',
			headerName: strings.name,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link component={LinkRouter} to={`/users/${params.id}`}>
					{params.value} {params.row.lastname}
				</Link>
			)
		},


		{
			field: 'email',
			headerName: strings.email,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link href={`mailto:${params.value}`}>
					{params.value}
				</Link>
			)
		},
		
		{
			field: 'points',
			headerName: strings.points,
			minWidth: 130,
			renderCell: (params) => (
				<Chip icon={<MonetizationOnOutlined style={{color:"E9B006"}} />} label={params.value} />
			)
		},

		{
			field: 'energy',
			headerName: strings.energy,
			minWidth: 130,
			renderCell: (params) => (
				<Chip icon={<Bolt style={{color: "D4B06A"}} />} label={params.value} />
			)
		},

		{
			field: 'rank',
			headerName: strings.rank,
			minWidth: 130,
			renderCell: (params) => (
				<>
				{ //console.log(params.value) 
				}
				{ params.value != null ?
					<Chip icon={<Star style={{color: "cda434"}}/>} label={params.value.name} />
					:
					<></>
				}
				</>
			)
		}
	], []);


    async function loadData(params) {

        const { authHeader, setAuthHeader } = helperHeader;

        try {

            const response = await axios.get(url + '/platforms/' + id, { headers: authHeader() })
            
            if (response) {
                const { platform, meta } = response.data;
                setTitle(`Users: ${platform.name}` );

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
            }
        }
		catch (error) {
            console.log(error);
            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }

        try {

            const { data: response } = await axios.get(url + `/platforms/${platform.id}/users`, {
                params: {
                    limit: 100,
                    sort: 'created_at'
                },

                headers: authHeader()
            });

            const { data, meta } = response;

            setRows(data);

            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }

        } catch (error) {

            console.log(error);

            if (error.response && error.response.status && error.response.status === 401) {

                sessionStorage.clear();
                return window.location.assign('/');
            }
        }
    }

    
    useEffect(() => {

        props.setSubMenu({
            sub : 'platform',
            id 
        });

        loadData();
    }, []);



    if (rows && rows.length && filter !== ''){
		rowData = [...rows].filter((e) => new RegExp(filter, 'i').test(e.name));	
	}
    else{
        rowData = rows;
    }


    return (

        <>

            <Toolbar sx={{ width: '100%' }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} >
                        <Link component={LinkRouter} to={`/platforms/${platform.id}`}>
                            {title}
                        </Link>
                    </Box>
                </Box>

            </Toolbar>


            <Box sx={{ display: 'flex', height: '90%' }}>

                <Box sx={{ flexGrow: 1 }}>

                    {rowData ?
                        <DataGrid
                            rows={rowData}
                            columns={columns}
                            pageSize={25}
                            disableSelectionOnClick
                            localeText={getDataGridLocale()}
                        />

                        :

                        <>
                            <Skeleton height={42} />
                            <Skeleton animation={false} height={42} />
                            <Skeleton animation="wave" height={42} />
                        </>
                    }

                </Box>
            </Box>
        </>
    )

}