import config from '../config';
import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button,  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link,  Paper,  Toolbar, Typography } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import { Link as LinkRouter } from 'react-router-dom';
import Draggable from 'react-draggable';
import axios from "axios"
import { DataGrid } from '@mui/x-data-grid';
import helperHeader from '../authHeader';
import {strings, getDataGridLocale} from '../strings.js'
import { ContactPage } from '@mui/icons-material';


export default function Clients(props) {

	const [load, setLoad] = useState(true);
    const [item, setItem] = useState(null);


    const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;
    const [myArray, setMyArray] = useState([]);
	

    let rows = [...myArray];

	const [openDialog, setOpenDialog] = useState(false);

	function ConfirmDialog(props) {

		const { authHeader, setAuthHeader } = helperHeader;
		
		async function onClickAction(event){

			try {
				const r = await axios.patch(url + '/clients/' + item.id, {
					status: item.status ? 0 : 1
				}, { headers: authHeader() })

				const { meta } = r.data;

				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}
			catch (error) {
				console.log(error)
				alert(error.message)

				if (error.response && error.response.status && error.response.status === 401) {
					sessionStorage.clear();
					window.location.assign('/');
				}

				const { meta } = error.response.data;
				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}

			const fullItem = myArray.find(element => element.id === item.id);
			fullItem.status = item.status ? 0 : 1;

			setOpenDialog(false)
		}

		
		return (

			<Dialog
				open={openDialog}
				onClose={(event, reason) => { if (reason && reason === "backdropClick") return; setOpenDialog(false) }}
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					{strings.changeStatus}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{strings.statusChangeConfirmation}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClickAction}>
						{strings.ok}
					</Button>

					<Button autoFocus onClick={() => setOpenDialog(false)}>
						{strings.cancel}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}


    const columns = useMemo( () => [
		{
			field: 'id',
			headerName: strings.id,
			width: 90
		},
		{
			field: 'name',
			headerName: strings.name,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link component={LinkRouter} to={`/clients/${params.id}`}>
					{params.value}
				</Link>
			)
		},
		{
			field: 'email',
			headerName: strings.email,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link href={`mailto:${params.value}`}>
					{params.value}
				</Link>
			)
		},{
			field: 'status',
			headerName: strings.status,
			width: 150,
			renderCell: (params) => <Button onClick={() => {

				setItem({ id: params.id, status: params.value })
				setOpenDialog(true)

			}}
				color={params.value === 1 ? 'success' : 'error'} size="small">{params.value === 1 ? strings.enabled : strings.disabled}</Button>
		}
	], []);
	


    useEffect(() => {

		if (!load) return;

		const { authHeader, setAuthHeader } = helperHeader;

		const  loadData = async () => {

			try {
				const response = await axios.get(url + '/clients', {
					params: {
						limit: 100,
						sort: 'name'
					},
					headers: authHeader()
				})

				if (response) {
                    const { data, meta } = response.data;
					setMyArray(data)
	
					if (meta && meta.token) {
						setAuthHeader(meta.token);
					}
				}
		

			} catch (error) {
				console.log(error);
	
				alert(error.message);
	
				if (error.response && error.response.status && error.response.status === 401) {
	
					sessionStorage.clear();
					return window.location.assign('/');
				}
	
				const { meta } = error.response.data;
				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}
		}

		loadData();

		console.count('useEffect')
		
		setLoad(false);

	}, [load, url]);


  

    function PaperComponent(props) {
		return (
			<Draggable
				handle="#draggable-dialog-title"
				cancel={'[class*="MuiDialogContent-root"]'}
			>
				<Paper {...props} />
			</Draggable>
		);
	}

   
   

    return (

		<Box sx={{ display: 'flex', height: '90%' }}>

			<Box sx={{ flexGrow: 1 }}>

				<Box sx={{ height: '10%' }}>

					<Toolbar>
						<ContactPage sx={{fontSize: 40, mr: 2}} />

						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{strings.clients}
						</Typography>


						<IconButton title={strings.reload} color="primary" component="span" onClick={ () => setLoad(true)} sx={{ mr: 1 }} ><RotateLeftIcon /></IconButton>

						<Button component={LinkRouter} to={`/clients/0`} startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary">{strings.add} --- </Button>
					</Toolbar>
				</Box>


				{rows.length > 0 && (

					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={25}
						checkboxSelection
						disableSelectionOnClick
						localeText={getDataGridLocale()}
					/>
				)}
				
			</Box>

			<ConfirmDialog />
		</Box>
	);
}