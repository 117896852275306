import config from '../config';
import React, { useState, useEffect, useMemo } from 'react'
import {Autocomplete, Box,  Chip, IconButton, Link, TextField, Toolbar, Typography } from '@mui/material';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Link as LinkRouter } from 'react-router-dom';
import axios from "axios"
import { DataGrid } from '@mui/x-data-grid';
import helperHeader from '../authHeader';
import {strings, getDataGridLocale} from '../strings.js'
import { Bolt, MonetizationOnOutlined, PeopleAlt, Star } from '@mui/icons-material';

//const { typeOptions } = config;

export default function Users(props) {
	const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

	const [load, setLoad] = useState(true);
	const [myArray, setMyArray] = useState(undefined);

	const {filter = undefined } = props;
	
	const [filterPanel, setFilterPanel] = useState(false);

	const [filterLocal, setFilterLocal] = useState({text: filter, platform: null });
	const [values,setValues]= useState([])
	const [platformOptions, setPlatformsOptions] = useState([]);
	
	function FilterPanelGui(props) {
		 

		const handleChange = () => (event, value) =>{

			setValues(value);
			
			//console.log( value );
			
			onChangePlatforms(value.map(item=> item.id))
		}

		const onChangePlatforms = async param =>{
			
			const data = { 
				...filterLocal, 
				platform: param
			}
		
			
			setFilterLocal({...data}) ;
			sessionStorage.setItem("filter_client", JSON.stringify( data ) );
		} 


		return (
			<Toolbar sx={{width: '100%'}}>

				
				<Box sx={{ 
					display: 'flex',
          			justifyContent: 'flex-start',
					width: '100%'
				}} >
				
				
				<Autocomplete
					multiple
					id="tags-filled"
					value={values}
					options={platformOptions}

					getOptionLabel={(option) =>  `${option.name} - ${option.clients.name}`}
					//getOptionSelected={(option, value) => option.id === value.id}

					onChange={ handleChange() }
					filterSelectedOptions
					fullWidth
					
					renderInput={(params) => <TextField {...params} />}
				/>

					{/* <Select
						name="platforms"
						labelId="select-platform-label"
						id="platform-select"
						label={strings.platforms}
						
						variant="standard"
						value={filterLocal.platform}
						onChange={onChangePlatforms}

						sx={{ mb: 4, minWidth: '200px', mr: 5 }}
					>

						{platformOptions.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.name}
							</MenuItem>
						))}

					</Select> */}

				</Box>

			</Toolbar>
		)
	}



	const columns = useMemo( () => [
		{
			field: 'id',
			headerName: strings.id,
			width: 90
		},
		
		{
			field: 'firstname',
			headerName: strings.name,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link component={LinkRouter} to={`/users/${params.id}`}>
					{params.value} {params.row.lastname}
				</Link>
			)
		},


		{
			field: 'email',
			headerName: strings.email,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link href={`mailto:${params.value}`}>
					{params.value}
				</Link>
			)
		},
		{
			field: 'platform',
			headerName: strings.platform,
			minWidth: 250,
			renderCell: (params) => (
				<Link component={LinkRouter} to={`/platforms/${params.value.id}`}>
					{params.value.name}
				</Link>

			)
		},


		{
			field: 'points',
			headerName: strings.points,
			minWidth: 130,
			renderCell: (params) => (
				<Chip icon={<MonetizationOnOutlined style={{color:"E9B006"}} />} label={params.value} />
			)
		},

		{
			field: 'energy',
			headerName: strings.energy,
			minWidth: 130,
			renderCell: (params) => (
				<Chip icon={<Bolt style={{color: "D4B06A"}} />} label={params.value} />
			)
		},

		{
			field: 'rank',
			headerName: strings.rank,
			minWidth: 130,
			renderCell: (params) => (
				<>
				{ //console.log(params.value) 
				}
				{ params.value != null ?
					<Chip icon={<Star style={{color: "cda434"}}/>} label={params.value.name} />
					:
					<></>
				}
				</>
			)
		}
	], []);


	useEffect(() => {

		props.setSubMenu({});

		if (!load ) return;

	
		const { authHeader, setAuthHeader } = helperHeader;

		const loadData = async (value = undefined) => {
		
			try {

				const response = await axios.get(url + '/users', {
					
					params: {
						limit: 100,
						sort: 'name'
					},
		
					headers: authHeader()
		
				});
				
				if (response){

					const { data, meta } = response.data;
		
					setMyArray(data)
		
					if (meta && meta.token) {
						setAuthHeader(meta.token);
					}

				}
				
			} catch (error) {

				console.log(error);
                alert(error.message);
    
                if (error.response && error.response.status && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.assign('/');
                }
    
                const { meta } = error.response.data;
                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
			}
		}


		const loadPlatformsData = async () => {

			try {
				const response = await axios.get(url + '/platforms', {
					params: {
						limit: 100,
						sort: 'name'
					},
					headers: authHeader()
				})

				if (response){
					const { data, meta } = response.data;
					//const option = data.map(item => {return {value: item.id, label:item.name}})
		
					setPlatformsOptions([...data]);
		
					if (meta && meta.token) {
						setAuthHeader(meta.token);
					}
				}

			} catch (error) {
				console.log(error);
	
				alert(error.message);
	
				if (error.response && error.response.status && error.response.status === 401) {
	
					sessionStorage.clear();
					return window.location.assign('/');
				}
	
				const { meta } = error.response.data;
				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}
		}
		
		loadPlatformsData();
		loadData();

		if (sessionStorage.getItem("filter_platform")){
			setFilterLocal(JSON.parse(sessionStorage.getItem("filter_platform")))
		}

		console.count('useEffect');

		setLoad(false);

	}, [load, url]);


	let rows = myArray ? [...myArray] : [];

	filterLocal.text = filter;

	if (rows.length &&  filterLocal.text !== ''){
		rows = [...rows].filter((e) => new RegExp(filterLocal.text).test(e.platform.name) );	
	}


	if ( rows.length &&  filterLocal.platform  && Array.isArray(filterLocal.platform) && filterLocal.platform.length ){

		//console.log( filterLocal.platform );
		//console.log( typeof filterLocal.platform );

		
		rows = [...rows].filter( 
			e => e.platform && filterLocal.platform.includes( e.platform.id ) );	
	}


	return (

		<Box sx={{ display: 'flex', height: '90%' }}>

			<Box sx={{ flexGrow: 1 }}>

				<Box sx={{ height: '10%' }}>

					<Toolbar>
						<PeopleAlt sx={{fontSize: 40, mr: 2}} />

						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{strings.users}
						</Typography>

						<IconButton title={strings.filter} sx={{ mr: 1 }} color="primary" component="span" onClick={() => setFilterPanel(!filterPanel)}>
							{filterPanel ? <FilterAltOffIcon></FilterAltOffIcon> : <FilterAltIcon />}
						</IconButton>

						<IconButton title={strings.reload} color="primary" component="span" onClick={() => setLoad(true) } sx={{ mr: 1 }} ><RotateLeftIcon /></IconButton>
						
					</Toolbar>
				</Box>

				{filterPanel &&
					<Box sx={{ height: '10%' }}>
						<FilterPanelGui/>
					</Box>
				}

				{rows.length > 0 && (

					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={25}
						checkboxSelection
						disableSelectionOnClick
						localeText={getDataGridLocale()}
					/>
				)}

			</Box>

		
		</Box>
	);
}