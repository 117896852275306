import React, { useRef } from 'react';
import config from '../config';
import { Avatar, Box, Button, Container, CssBaseline, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

//import useBrowserLanguageDetector from '../hooks/useBrowserLanguageDetector'

import {strings} from '../strings.js'

export default function SignIn(props) {
	//const browserLanguage = useBrowserLanguageDetector()

    const browserLanguage = 'it'
	
    strings.setLanguage(browserLanguage)

	const navigate = useNavigate();
    const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

    const inputUsername = useRef();
    const inputPassword = useRef();



    const handleSubmit = (event) => {

        event.preventDefault();

        const payload = {
            grant_type: "password",
            username: inputUsername.current.value ,
            password: inputPassword.current.value
        }


        axios.post(url + '/oauth/access_token', {...payload} ).then(function (response) {

            const { token } = response.data;

            console.log(token);
    
            sessionStorage.setItem('session', JSON.stringify({token}));        
            navigate(0);

            

        }).catch(function (error) {
            
            let msg = error.message;

            console.log(error);

            if (error.response && error.response.status && error.response.status === 401){
                msg += "\n" + error.response.statusText;
            }

            alert(msg);

        })
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ width: 100, height: 100, mb: 4, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon sx={{fontSize: 80}}  />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {strings.logIn}
                </Typography>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5 }}>
                  <TextField
                        inputRef={inputUsername}
                        margin="normal"
                        required
                        fullWidth
                        label={strings.userName}
                        autoComplete="username"
                        autoFocus
                    />

					<TextField
                        inputRef={inputPassword}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={strings.password}
                        type="password"
                        autoComplete="current-password"
                    />
  
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3}}
                    >
                        {strings.logIn}
                    </Button>
                </Box> 
            </Box>
        </Container>
    )
}