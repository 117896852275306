import config from '../config';
import { Link } from 'react-router-dom';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, FormGroup, Grid, Toolbar, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import helperHeader from '../authHeader';
import BrowserThemeContext from '../BrowserThemeContext'
import {strings} from '../strings.js'

export default function Settings(props) {
	const [languageSetting, setLanguageSetting] = useState(localStorage.getItem('language') || 'default')
	const [themeSetting, setThemeSetting] = useState(localStorage.getItem('theme') || 'default')
	const setBrowserTheme = useContext(BrowserThemeContext)
    const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;
	const { authHeader, setAuthHeader } = helperHeader;
    const loadData = () => {}

    async function onRestart() {
        try {
            const t = await axios.put(url + '/tools/restart' , {email : config.emailCertbot}, { headers: authHeader() });
            const { meta } = t.data;

            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }
		catch (error) {
            console.log(error);
			alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                return window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }
    }

    useEffect(() => {
        loadData();
    }, []);

	const onChangeLanguage = (event) => {
		const newLanguage = event.target.value
		setLanguageSetting(newLanguage)
		if (newLanguage === 'default') {
			// setBrowserLanguage(window.navigator.language.slice(0, 2))
			localStorage.removeItem('language')
		}
		else {
			// setBrowserLanguage(newLanguage)
			localStorage.setItem('language', newLanguage)
		}
	}

	const onChangeTheme = (event) => {
		const newTheme = event.target.value
		setThemeSetting(newTheme)
		if (newTheme === 'default') {
			setBrowserTheme(window.matchMedia("(prefers-color-scheme: light)").matches ? 'light' : 'dark')
			localStorage.removeItem('theme')
		}
		else {
			setBrowserTheme(newTheme)
			localStorage.setItem('theme', newTheme)
		}
	}

	return (
        <Box sx={{ display: 'flex', height: '90%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ height: '10%' }}>
                    <Toolbar>
                        <SettingsIcon sx={{fontSize: 40, mr: 2}} />

                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {strings.settings}
                        </Typography>

                        <Button component={Link} to={`/domains`} startIcon={<ArrowBackIcon />} variant="contained" color="primary">{strings.back}</Button>
                    </Toolbar>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', p: 5, height: '100%', overflowY: 'auto' }}>
                    <Grid container maxWidth="md" spacing={3}>
					<Grid item xs={12} sm={6}>
							<FormGroup>
								<FormControl>
									<InputLabel id="language-setting-label">{strings.language}</InputLabel>
									<Select
										labelId="language-setting-label"
										id="language-setting"
										value={languageSetting}
										label="Language"
										onChange={onChangeLanguage}
									>
										<MenuItem value={'default'}>{strings.default}</MenuItem>
										<MenuItem value={'en'}>{strings.english}</MenuItem>
										<MenuItem value={'it'}>{strings.italian}</MenuItem>
									</Select>
								</FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormGroup>
								<FormControl>
									<InputLabel id="theme-setting-label">{strings.theme}</InputLabel>
									<Select
										labelId="theme-setting-label"
										id="theme-setting"
										value={themeSetting}
										label="Theme"
										onChange={onChangeTheme}
									>
										<MenuItem value={'default'}>{strings.default}</MenuItem>
										<MenuItem value={'light'}>{strings.light}</MenuItem>
										<MenuItem value={'dark'}>{strings.dark}</MenuItem>
									</Select>
								</FormControl>
							</FormGroup>
						</Grid>

                        <Grid item xs={12} sm={6}>
							<Button type='button' onClick={onRestart} endIcon={<RestartAltIcon />} variant="contained" color="info">{strings.restartWebServer}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
