import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import config from "../../config.js";
import helperHeader from '../../authHeader.js';
import { strings, getDataGridLocale } from '../../strings.js'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, MenuItem, Skeleton, TextField, Toolbar } from "@mui/material";
import ActionAreaCard from "../tools/ActionAreaCard.js";
import { Add, Check, Close, ControlPointDuplicate, Remove } from "@mui/icons-material";
import { Link as LinkRouter, useParams } from "react-router-dom";


let selectedRows = [];
let eventsList = [];

const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

let rowData = null;

export default function DataGridPlatformRewards(props) {
    //const [events, setEvents] = useState([]) ;

    const { id = '0' } = useParams();

    const { filter } = props;

    const platform = {id};
    
    const [title, setTitle] = useState(null);

    const [openDialog, setOpenDialog] = useState({
        show: false,
        id: null
    });

    const [rows, setRows] = useState(undefined);
    const [openConfim, setOpenConfim] = useState(false);

    const [languages, setLanguage] = useState([]);
   

    async function onOpenDialog(id) {

        const { authHeader, setAuthHeader } = helperHeader;

        try {

            if (id !== undefined) {

                const response = await axios.get(url + '/reward/' + id, {
                    params: {},
                    headers: authHeader()
                })


                if (response) {
                    const { data, meta } = response.data;

                    //setEvents(meta.events_list);

                    setOpenDialog({
                        id,
                        show: true,
                        reward: {
                            ...data,
                            eventObj: eventsList.filter(item => item.code === data.event)[0]
                        }
                    })

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }
                }
            }

            else {
                setOpenDialog({
                    id,
                    show: true,
                    reward: null
                })
            }

        } catch (error) {
            console.log(error);

            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {

                sessionStorage.clear();
                return window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }

    }


    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: strings.id,
            width: 80
        }, {
            field: 'name',
            headerName: strings.name,
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <>
                    <Button onClick={e => onOpenDialog(params.id)} > {params.value} </Button>
                </>
            )
        },

        {
            field: 'count',
            headerName: strings.count,

            renderCell: (params) => (
                <>{params.value}</>
            )
        },

        {
            field: 'limit_type',
            headerName: strings.limit_type,

            renderCell: (params) => (
                <>{params.value}</>
            )
        },

        {
            field: 'limit',
            headerName: strings.limit,

            renderCell: (params) => (

                <>{params.row.limit_type !== 'unlimited' ? params.value : ''}</>
            )
        },



        {
            field: 'maximum_earnings',
            headerName: strings.maximum_earnings,

            renderCell: (params) => (
                <>{params.value}</>
            )
        },

        {
            field: 'points',
            headerName: strings.points,

            renderCell: (params) => (
                <>{params.value}</>
            )
        },

        {
            field: 'energy',
            headerName: strings.energy,

            renderCell: (params) => (
                <>{params.value}</>
            )
        },

        {
            field: 'created_at',
            headerName: strings.createdAt,
            minWidth: 250,
            renderCell: (params) => {
                const d = new Date(params.value);
                return (
                    <>{d.toLocaleString()}</>
                )
            }
        }
    ], []);


    async function onDelete(e) {

        if (selectedRows.length > 0) {

            const { authHeader } = helperHeader;

            const promises = [];

            selectedRows.forEach(element => {

                promises.push(axios.delete(url + `/reward/${element}`, {
                    headers: authHeader()
                }));
            });

            Promise.all(promises).then(values => {
                loadData();
            })
        }
        else {
            alert(strings.noRewardSelect)
        }

        setOpenConfim(false)
    }


    async function onDuplicate(e) {

        if (selectedRows.length === 1) {

            const element = selectedRows[0];

            const { authHeader } = helperHeader;

            const { data: response } = await axios.get(url + `/reward/${element}`, {
                headers: authHeader()
            });

            const { data } = response;

            try {

                const { data: reward } = await axios.post(url + '/reward', {
                    name: data.name + ' ' + strings.copy,
                    description: data.description,
                    type: 0,
                    platform_id: data.platform.id,

                }, { headers: authHeader() })

                const { id } = reward;

                await axios.post(url + '/reward/' + id + '/rule', {
                    event: data.event,
                    count: data.count,
                    url: data.url,
                    limit: data.limit,
                    limit_type: data.limit_type,
                    energy: data.energy,
                    points: data.points,
                    maximum_earnings: data.maximum_earnings
                }, { headers: authHeader() })

                loadData();

            } catch (error) {
                console.log(error);
            }
        }
        else {
            alert(strings.noRewardSelect);
        }

    }



    async function loadData(params) {

        const { authHeader, setAuthHeader } = helperHeader;

        try {

            const response = await axios.get(url + '/platforms/' + id, { headers: authHeader() })

            if (response) {
                const { platform, meta } = response.data;
                setTitle(`Rewards: ${platform.name}`);

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            }
        }
        catch (error) {
            console.log(error);
            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }


        try {

            const { data: response } = await axios.get(url + `/platforms/${platform.id}/rewards`, {
                params: {
                    limit: 100,
                    sort: 'created_at'
                },

                headers: authHeader()
            });

            const { data, meta } = response;

            setRows(data);

            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }

        } catch (error) {

            console.log(error);

            if (error.response && error.response.status && error.response.status === 401) {

                sessionStorage.clear();
                return window.location.assign('/');
            }
        }
    }


    function ConfirmDialog(props) {

        const {
            title = strings.confirm,
            body = strings.deleteConfirm
        } = props;


        return (

            <Dialog
                open={openConfim}
                onClose={(event, reason) => { if (reason && reason === "backdropClick") return; setOpenConfim(false) }}
                aria-labelledby="draggable-dialog-title"
                fullWidth={true}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {body}
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="error" endIcon={<Close />} onClick={e => setOpenConfim(false)}>{strings.cancel}</Button>
                    <Button variant="contained" color="primary" endIcon={<Check />} onClick={onDelete}>{strings.delete}</Button>
                </DialogActions>
            </Dialog>
        )
    }


    let contents = new Map();


    function FormDialog(props) {

        const [reward, setReward] = useState(openDialog.reward ?? {});
        const [currentLanguages, setcurrentLanguages] = useState("1");
        

        async function onClickAction(apply = false) {

            const { authHeader } = helperHeader;

            try {

                const { id, name, description } = reward;

                if (id) {

                    const { data } = await axios.patch(url + '/reward/' + id, {
                        name,
                        description,
                        platform_id: platform.id
                    }, { headers: authHeader() })

                    //console.log(data);

                    const { event, count, url: reward_url, limit, limit_type, energy, points, maximum_earnings } = reward
                    await axios.patch(url + '/reward/' + id + '/rule', {
                        event,
                        count,
                        url: reward_url,
                        limit,
                        limit_type,
                        energy,
                        points,
                        maximum_earnings
                    }, { headers: authHeader() })

                    //console.log(rule);
                }
                else {

                    const { data } = await axios.post(url + '/reward', {
                        name,
                        description,
                        type: 0,
                        platform_id: platform.id,

                    }, { headers: authHeader() })

                    const { id } = data;

                    const { event, count, url: reward_url, limit, limit_type, energy, points, maximum_earnings } = reward
                    const { rule } = await axios.post(url + '/reward/' + id + '/rule', {
                        event,
                        count,
                        url: reward_url,
                        limit,
                        limit_type,
                        energy,
                        points,
                        maximum_earnings
                    }, { headers: authHeader() })

                    reward.id = id;

                    //console.log(rule);
                }

                //console.log('apply', contents);

                const promises = [];
                contents.forEach( (value, key) => {
                    promises.push(axios.post(url + '/reward/' +  reward.id + '/language/' + key, value, { headers: authHeader() }))
                } )

                Promise.all(promises).then( values => {
                    contents = new Map();
                })


                if (!apply) {
                    setOpenDialog({
                        show: false,
                        id: null,
                        reward: null
                    })

                    loadData();
                }

            } catch (error) {
                alert(error);
            }
        }


        function onCloseAction() {

            setOpenDialog({
                show: false,
                id: null,
                reward: null
            })
        }


        let disableUrl = true;

        if (reward.eventObj && reward.eventObj.clientsOptions && reward.eventObj.clientsOptions.url) {
            disableUrl = false;
        }

        async function onChangeLanguage(lang_id){

            const { authHeader, setAuthHeader } = helperHeader;

            try {

                const response = await axios.get(url + `/reward/${reward.id}/language/${lang_id}`, { headers: authHeader() })

                if (response) {

                    const { data, meta } = response.data;

                    //console.log(data);
                    
                    setReward( r => ({
                        ...r,
                        nameLang : data ? data.name : '',
                        descriptionLang : data ? data.description : ''
                    }) )
                    

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                }
            }
            catch (error) {
                console.log(error);
                alert(error.message);

                if (error.response && error.response.status && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.assign('/');
                }

                const { meta } = error.response.data;
                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
            }
        
            setcurrentLanguages(lang_id);

        } 
        
        const disableLimit = reward && reward.limit_type === 'unlimited'

        
        //console.log(contents);

        useEffect(() => {

            reward.id && onChangeLanguage(currentLanguages);
        },[]);
                                            

        return (

            <Dialog
                open={openDialog.show}
                onClose={(event, reason) => { if (reason && reason === "backdropClick") return; onCloseAction() }}
                aria-labelledby="draggable-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {reward ? `${reward.name} #${openDialog.id}` : strings.addNew}
                </DialogTitle>

                <DialogContent>

                    <Grid container spacing={2} sx={{ mb: 4 }} >

                        <Grid item xs={12} md={8}>

                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', }}>

                                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>

                                    <label>{strings.name}</label>

                                    <TextField
                                        name="name"
                                        variant="standard"
                                        value={reward ? reward.name : ""}
                                        onChange={e => setReward({
                                                ...reward,
                                                name: e.target.value
                                            })
                                        }
                                        sx={{ width: '100%', minWidth: '200px', mb: 4 }}
                                    />
                                </Box>


                                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mb: 4 }}>

                                    <Box sx={{mr: 2, width: '80%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                        <label>{strings.name}</label>

                                        <TextField
                                            name="name"
                                            variant="standard"
                                            value={reward ? reward.nameLang : ''}
                                            onChange={e => {
                                            
                                                setReward({
                                                    ...reward,
                                                    nameLang: e.target.value
                                                })

                                                const lang = contents.get(currentLanguages);
                                            
                                                contents.set(currentLanguages , {
                                                    ...lang,
                                                    name : e.target.value
                                                });
                                            }}

                                            sx={{ width: '100%', minWidth: '200px' }}
                                        />
                                    </Box>

                                    <Box sx={{ width: '20%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                        <label>{strings.select}</label>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            variant="standard"
                                            sx={{ minWidth: '100px' }}

                                            onChange={e => onChangeLanguage(e.target.value) }
                                            value={currentLanguages ?? config.language.default}
                                        >
                                            {languages.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}

                                        </TextField>
                                    </Box>
                                </Box>

                                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>

                                    <label>{strings.description}</label>
                                    <TextField

                                        name="description"
                                        multiline
                                        rows={2}
                                        variant="standard"
                                        value={reward ? reward.descriptionLang : ""}
                                        onChange={e => {

                                            setReward({
                                                ...reward,
                                                descriptionLang: e.target.value
                                            })

                                            const lang = contents.get(currentLanguages);
                                            
                                            contents.set(currentLanguages , {
                                                ...lang,
                                                description : e.target.value
                                            })
                                        }}

                                        sx={{ mb: 4, minWidth: '200px' }}
                                    />

                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ width: '100%', marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                                {openDialog.id !== undefined && (
                                    <ActionAreaCard object={{ entity: 'reward', ...reward }} />
                                )}
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >

                        {/* <Typography variant="h5" gutterBottom>{strings.rule}</Typography>        */}

                        <Grid container spacing={2} sx={{ mb: 4 }} >

                            <Grid item md={7}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                    <label>{strings.when}</label>

                                    <TextField
                                        label={strings.event}
                                        select
                                        required
                                        name="event"
                                        variant="standard"
                                        value={reward ? reward.event : ""}
                                        onChange={e => setReward({
                                            ...reward,
                                            event: e.target.value,
                                            eventObj: eventsList.filter(item => item.code === e.target.value)[0]
                                        })}

                                        sx={{ minWidth: '200px' }}
                                    >

                                        {eventsList.map((item, k) => <MenuItem key={k} value={item.code} >{item.code}</MenuItem>)}

                                    </TextField>
                                </Box>
                            </Grid>

                            <Grid item md={5}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                    <label>{strings.count}</label>
                                    <TextField
                                        label={strings.countLabel}
                                        variant="standard"
                                        //placeholder={0}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={reward ? reward.count : ''}

                                        onChange={e => setReward({
                                            ...reward,
                                            count: e.target.value
                                        })}
                                    />


                                </Box>
                            </Grid>

                        </Grid>

                        <Grid sx={disableUrl ? { display: 'none', mb: 4 } : { display: 'block', mb: 4 }} container spacing={4} >
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>

                                    <label disabled={disableUrl} >{strings.url}</label>
                                    <TextField

                                        disabled={disableUrl}
                                        name="url"
                                        variant="standard"
                                        value={reward ? reward.url : ''}
                                        onChange={e => setReward({
                                            ...reward,
                                            url: e.target.value
                                        })}

                                    />
                                </Box>

                            </Grid>

                        </Grid>


                        <Grid container spacing={2} sx={{ mb: 4 }}>

                            <Grid item md={7}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>

                                    <label>{strings.limitType}</label>

                                    <TextField
                                        label={strings.limitTypeLabel}
                                        select
                                        required
                                        name="event"
                                        variant="standard"
                                        value={reward ? reward.limit_type : null}
                                        onChange={e => setReward({
                                            ...reward,
                                            limit_type: e.target.value
                                        })}
                                    >
                                        
                                        {config.limitType.map((item, k) => <MenuItem key={k} value={item.value} >{item.label}</MenuItem>)}

                                    </TextField>
                                </Box>
                            </Grid>

                            <Grid item md={5}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                    <label disabled={disableLimit} >{strings.limit}</label>

                                    <TextField
                                        label={strings.limitLabel}
                                        variant="standard"
                                        disabled={disableLimit}
                                        // placeholder={0}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={reward ? reward.limit : ''}
                                        onChange={e => setReward({
                                            ...reward,
                                            limit: e.target.value
                                        })}

                                    />


                                </Box>
                            </Grid>



                        </Grid>


                        <Grid container spacing={2} sx={{ mb: 4 }} >

                            <Grid item md={4}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                    <label>{strings.maximumEarnings}</label>

                                    <TextField
                                        label={strings.maximumEarningsLabel}
                                        variant="standard"
                                        //placeholder={0}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={reward ? reward.maximum_earnings : ''}
                                        onChange={e => setReward({
                                            ...reward,
                                            maximum_earnings: e.target.value
                                        })}


                                    />

                                </Box>
                            </Grid>

                            <Grid item md={4}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                    <label>{strings.points}</label>

                                    <TextField
                                        label={strings.pointsLabel}
                                        variant="standard"
                                        //placeholder={0}
                                        type="number"
                                        value={reward ? reward.points : ''}
                                        onChange={e => setReward({
                                            ...reward,
                                            points: e.target.value
                                        })}


                                    />

                                </Box>
                            </Grid>

                            <Grid item md={4}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justiifyContent: 'space-between', alignContent: 'align items' }}>
                                    <label>{strings.energy}</label>

                                    <TextField
                                        label={strings.energyLabel}
                                        variant="standard"
                                        //placeholder={0}
                                        type="number"
                                        value={reward ? reward.energy : ''}
                                        onChange={e => setReward({
                                            ...reward,
                                            energy: e.target.value
                                        })}

                                    />

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" endIcon={<Close />} onClick={onCloseAction}>{strings.cancel}</Button>
                    <Button disabled={reward === null} variant="contained" color="warning" endIcon={<Check />} onClick={e => onClickAction(true)}>{strings.apply}</Button>
                    <Button disabled={reward === null} variant="contained" color="primary" endIcon={<Check />} onClick={e => onClickAction(false)}>{strings.save}</Button>
                </DialogActions>
            </Dialog>
        )
    }


    async function loadEvents() {
        const { authHeader } = helperHeader;

        const { data: response } = await axios.get(url + `/reward/events`, {
            headers: authHeader()
        });

        const { data } = response;
        eventsList = [...data];
    }



    useEffect(() => {
        

        async function loadlanguage() {

            const { authHeader, setAuthHeader } = helperHeader;

            try {

                const response = await axios.get(url + '/languages', { headers: authHeader() })

                if (response) {

                    const { data, meta } = response.data;

                    setLanguage(data.map( x => ({ "value" : x.id, "label" : x.value })))

                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }

                }
            }
            catch (error) {
                console.log(error);
                alert(error.message);

                if (error.response && error.response.status && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.assign('/');
                }

                const { meta } = error.response.data;
                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
            }
        }

        props.setSubMenu({
            sub: 'platform',
            id
        });

        loadData();
        loadEvents();

        loadlanguage();


    }, []);



    if (rows && rows.length && filter !== '') {

        //console.log(filter, rows);

        rowData = [...rows].filter((e) => new RegExp(filter, 'i').test(e.name));
    }
    else {
        rowData = rows;
    }


    return (

        <>
            <ConfirmDialog
                title={strings.confirm}
                body={strings.confirmDelete}
            />

            <FormDialog />

            <Toolbar sx={{ width: '100%' }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} >
                        <Link component={LinkRouter} to={`/platforms/${platform.id}`}>
                            {title}
                        </Link>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} >
                        <Button type='button' onClick={e => onOpenDialog()} endIcon={<Add />} variant="contained" color="primary" sx={{ mr: 1 }}>{strings.add}</Button>
                        <Button type='button' onClick={e => onDuplicate()} endIcon={<ControlPointDuplicate />} variant="contained" color="warning" sx={{ mr: 1 }}>{strings.duplicate}</Button>
                        <Button type='button' onClick={e => setOpenConfim(true)} endIcon={<Remove />} variant="contained" color="error">{strings.delete}</Button>
                    </Box>
                </Box>

            </Toolbar>


            <Box sx={{ display: 'flex', height: '90%' }}>

                <Box sx={{ flexGrow: 1 }}>

                    {rowData ?
                        <DataGrid
                            rows={rowData}
                            columns={columns}
                            pageSize={25}
                            checkboxSelection
                            disableSelectionOnClick
                            onSelectionModelChange={e => {
                                selectedRows = [...e]
                            }}
                            localeText={getDataGridLocale()}
                        /> :

                        <>
                            <Skeleton height={42} />
                            <Skeleton animation={false} height={42} />
                            <Skeleton animation="wave" height={42} />
                        </>
                    }

                </Box>
            </Box>
        </>
    )

}