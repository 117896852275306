import LocalizedStrings from 'react-localization';
import { enUS, itIT } from '@mui/x-data-grid'

export const strings = new LocalizedStrings({
	en: {
		add: 'Add',
		addNew: 'Add new',
		all: 'All',
		apply: 'Apply',
		badges: 'Badges',
		birthday: "Birthday",
		cancel: 'Cancel',
		changeStatus: 'Change status',
		clients: "Clients",
		client: "Client",
		confirm: 'Confirm',
		confirmDelete: 'Delete selected items?',
		copy: 'Copy',
		count: 'Count',
		countLabel: 'Number of times the action should be performed',
		cpu: 'CPU',
		custom: 'Custom',
		createdAt: 'Created at',
		database: 'Database',
		dark: 'Dark',
		data: 'Data',
		daily: 'Daily',
		delete: 'Delete',
		deleteConfirm: 'Are you sure you want to delete the selected element?',
		deleteDomain: 'Delete the domain',
		description: 'Description',
		details: 'Details',
		disable: 'Disable',
		disabled: 'Disabled',
		domains: 'Domains',
		domainDeletionConfirmation: 'The deletion of the domain is irreversible. If you are sure you want to delete the domain, type "delete domain".',
		domainName: 'Domain name',
		duplicate: 'Duplicate',
		edit: 'Edit',
		email: 'Email',
		enabled: 'Enabled',
		english: 'English',
		energy: "Energy",
		energyLabel: "Amount of energy earned upon occurrence of the event",
		event: 'Event',
		events: 'Events',
		expiryDate: 'Expiry date',
		false: 'False',
		filter: 'Filter',
		firstName: 'First Name',
		hourly: 'Hourly',
		id: 'ID',
		instanceID: 'Instance ID',
		instanceType: 'Instance type',
		italian: 'Italian',
		language: 'Language',
		lastName: 'Last Name',
		limit: 'Limit',
		limitLabel: 'Limit referred to the Limit type field',
		limitType: 'Limit type',
		limit_type: 'Limit type',
		limitTypeLabel: 'Type of limit',
		light: 'Light',
		logIn: 'Log in',
		logs: 'Logs',
		logout: 'Log out',
		maxEarnings: 'Maximum earnings',
		maximumEarnings: 'Maximum earnings',
		maximum_earnings : 'Maximum earnings',
		maximumEarningsLabel: 'Number of times the rule must be executed',
		minutely: 'Minutely',
		monthly: 'Monthly',
		name: 'Name',
		newDomain: 'New domain',
		noRewardSelect: 'No reward is select ',
		normal: 'Normal',
		ok: 'OK',
		openOnTheWeb: 'Open on the Web',
		pageNotFound: 'Page not found.',
		parameters: 'Parameters',
		password: 'Password',
		platform: 'Platform',
		platforms: 'Platforms',
		points: "Points",
		pointsLabel: "Amount of points earned upon occurrence of the event",
		privateIP: 'Private IP',
		publicIP: 'Public IP',
		ranks: 'Ranks',
		rank: "Level",
		reload: 'Reload',
		repository: 'Repository',
		restartWebServer: 'Restart the Web server',
		reward: 'Reward',
		rewards: 'Rewards',
		root: 'Root',
		rule: 'Rule',
		save: 'Save',
		search: 'Search…',
		select : 'Select',
		servers: 'Servers',
		server: 'Server',
		serverStopConfirmation: 'Are you sure you want to stop the server?',
		settings: 'Settings',
		showAsGrid: 'Show as a grid',
		showAsList: 'Show as a list',
		sslTlsCertificate: 'SSL/TLS certificate',
		standard: 'Standard',
		started: 'Started',
		startServer: 'Start the server',
		status: 'Status',
		stop: 'Stop',
		stopServer: 'Stop the server',
		stopped: 'Stopped',
		theme: 'Theme',
		threadsPerCore: 'Threads per core',
		time: 'Time',
		totPoints: "Total points",
		true: 'True',
		type: 'Type',
		unlimited: 'Unlimited',
		url: 'Url',
		user: "User",
		userName: 'User name',
		users: "Users",
		yearly: 'Yearly',
		value: 'Value',
		weekly: 'Weekly',
		when: 'When',
		wrongCommand: 'Wrong command.'

	},
	it: {
		add: 'Aggiungi',
		addNew: 'Aggiungi nuovo',
		all: 'Tutti',
		apply: 'Applica',
		badges: 'Badge',
		birthday: "Compleanno",
		cancel: 'Annulla',
		changeStatus: 'Cambia stato',
		clients: "Clienti",
		client: "Cliente",
		confirm: 'Conferma',
		confirmDelete: 'Eliminare gli elementi selezionati?',
		copy: 'Copia',
		count: 'Conteggio',
		countLabel: 'Numero di volte in cui l\'azione deve essere eseguita',
		cpu: 'CPU',
		custom: 'Personalizzato',
		createdAt: 'Creato il',
		database: 'Database',
		dark: 'Scuro',
		data: 'Dati',
		daily: 'Giornaliero',
		delete: 'Elimina',
		deleteConfirm: 'Sei sicuro di voler eliminare l\'elemento selezionato?',
		deleteDomain: 'Elimina il dominio',
		description: 'Descrizione',
		details: 'Dettagli',
		disable: 'Disabilita',
		disabled: 'Disabilitato',
		domains: 'Domini',
		domainDeletionConfirmation: 'L\'eliminazione del dominio è irreversibile. Se sei sicuro di voler eliminare il dominio, digita "elimina dominio".',
		domainName: 'Nome del dominio',
		duplicate: 'Duplica',
		edit: 'Modifica',
		email: 'Email',
		enabled: 'Abilitato',
		english: 'Inglese',
		energy: "Energia",
		energyLabel: "Quantità di energia guadagnata alla comparsa dell'evento",
		event: 'Evento',
		events: 'Eventi',
		expiryDate: 'Data di scadenza',
		false: 'Falso',
		filter: 'Filtro',
		firstName: 'Nome',
		hourly: 'Ogni ora',
		id: 'ID',
		instanceID: 'ID istanza',
		instanceType: 'Tipo di istanza',
		italian: 'Italiano',
		language: 'Lingua',
		lastName: 'Cognome',
		limit: 'Limite',
		limitLabel: 'Limite riferito al campo Tipo di limite',
		limitType: 'Tipo di limite',
		limit_type: 'Tipo di limite',
		limitTypeLabel: 'Tipo di limite',
		light: 'Chiaro',
		logIn: 'Accedi',
		logs: 'Log',
		logout: 'Esci',
		maxEarnings: 'Guadagni massimi',
		maximumEarnings: 'Guadagni massimi',
		maximum_earnings: 'Guadagni massimi',
		maximumEarningsLabel: 'Numero di volte in cui la regola deve essere eseguita',
		minutely: 'Ogni minuto',
		monthly: 'Mensile',
		name: 'Nome',
		newDomain: 'Nuovo dominio',
		noRewardSelect: 'Nessuna ricompensa selezionata',
		normal: 'Normale',
		ok: 'OK',
		openOnTheWeb: 'Apri sul Web',
		pageNotFound: 'Pagina non trovata.',
		parameters: 'Parametri',
		password: 'Password',
		platform: 'Piattaforma',
		platforms: 'Piattaforme',
		points: "Punti",
		pointsLabel: "Quantità di punti guadagnati alla comparsa dell'evento",
		privateIP: 'IP privato',
		publicIP: 'IP pubblico',
		ranks: 'Livelli',
		rank: "Livello",
		reload: 'Ricarica',
		repository: 'Repository',
		restartWebServer: 'Riavvia il server Web',
		reward: 'Ricompensa',
		rewards: 'Ricompense',
		root: 'Radice',
		rule: 'Regola',
		save: 'Salva',
		search: 'Cerca…',
		select : 'Seleziona',
		servers: 'Server',
		server: 'Server',
		serverStopConfirmation: 'Sei sicuro di voler arrestare il server?',
		settings: 'Impostazioni',
		showAsGrid: 'Mostra come griglia',
		showAsList: 'Mostra come lista',
		sslTlsCertificate: 'Certificato SSL/TLS',
		standard: 'Standard',
		started: 'Avviato',
		startServer: 'Avvia il server',
		status: 'Stato',
		stop: 'Arresta',
		stopServer: 'Arresta il server',
		stopped: 'Arrestato',
		theme: 'Tema',
		threadsPerCore: 'Thread per core',
		time: 'Tempo',
		totPoints: "Punti totali",
		true: 'Vero',
		type: 'Tipo',
		unlimited: 'Illimitato',
		url: 'URL',
		user: "Utente",
		userName: 'Nome utente',
		users: "Utenti",
		yearly: 'Annuale',
		value: 'Valore',
		weekly: 'Settimanale',
		when: 'Quando',
		wrongCommand: 'Comando errato.',

	}
})
//.setLanguage('en');


export const getDataGridLocale = () => {
	let locale
	switch (strings.getLanguage()) {
		case 'en':
			locale = enUS.components.MuiDataGrid.defaultProps.localeText
			break
		case 'it':
			locale = itIT.components.MuiDataGrid.defaultProps.localeText
			break
		default:
			locale = enUS.components.MuiDataGrid.defaultProps.localeText
	}

	return locale
}
