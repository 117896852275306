import config from '../config';
import React, { useState, useEffect, useMemo } from 'react'
import {Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Paper, TextField, Toolbar, Typography } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Link as LinkRouter } from 'react-router-dom';
import Draggable from 'react-draggable';
import axios from "axios"
import { DataGrid } from '@mui/x-data-grid';
import helperHeader from '../authHeader';
import {strings, getDataGridLocale} from '../strings.js'
import { WebStories } from '@mui/icons-material';



export default function Platforms(props) {
	const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;
	const [load, setLoad] = useState(true);

	const [myArray, setMyArray] = useState([]);
	const {filter = undefined } = props;

	const [item, setItem] = useState(null);

	const [openDialog, setOpenDialog] = useState(false);
	const [filterPanel, setFilterPanel] = useState(false);

	const [filterLocal, setFilterLocal] = useState({text: filter, client: 0 });
	const [values,setValues]= useState([])
	const [clientsOptions, setClientsOptions] = useState([]);
	
	const { authHeader, setAuthHeader } = helperHeader;



	function ConfirmDialog(props) {


		function PaperComponent(props) {
			return (
				<Draggable
					handle="#draggable-dialog-title"
					cancel={'[class*="MuiDialogContent-root"]'}
				>
					<Paper {...props} />
				</Draggable>
			);
		}


		async function onClickAction(event){
			try {
				const r = await axios.patch(url + '/platforms/' + item.id, {
					status: item.status ? 0 : 1
				}, { headers: authHeader() })

				const { meta } = r.data;

				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}
			catch (error) {
				console.log(error)
				alert(error.message)

				if (error.response && error.response.status && error.response.status === 401) {
					sessionStorage.clear();
					window.location.assign('/');
				}

				const { meta } = error.response.data;
				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}

			const fullItem = myArray.find(element => element.id === item.id);
			fullItem.status = item.status ? 0 : 1;

			setOpenDialog(false)
		}


		return (
		
			<Dialog
				open={openDialog}
				onClose={(event, reason) => { if (reason && reason === "backdropClick") return; setOpenDialog(false) }}
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					{strings.changeStatus}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{strings.statusChangeConfirmation}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button onClick={onClickAction}>
						{strings.ok}
					</Button>

					<Button autoFocus onClick={() => setOpenDialog(false)}>
						{strings.cancel}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
	

	function FilterPanelGui(props) {
		
		const handleChange = () => (event, value) =>{

			setValues(value);
			
			
			onChangeClients(value.map(item=> item.id))
		}


		const onChangeClients = async param =>{

			const data = { 
				...filterLocal, 
				client: param
			}

			setFilterLocal({...data}) ;

			sessionStorage.setItem("filter_platform", JSON.stringify( data ) );

		
		} 


		return (
			<Toolbar sx={{width: '100%'}}>

				<Box sx={{ 
					display: 'flex',
          			justifyContent: 'flex-start',
					width: '100%'
				}} >


					<Autocomplete
						multiple
						id="tags-filled"
						value={values}
						options={clientsOptions}

						getOptionLabel={(option) =>  `${option.name}`}
						//getOptionSelected={(option, value) => option.id === value.id}

						onChange={ handleChange() }
						filterSelectedOptions
						fullWidth
						
						renderInput={(params) => <TextField {...params} />}
					/>

				
					{/* <Select
						name="clients"
						labelId="select-client-label"
						id="client-select"
						label={strings.clients}
						
						variant="standard"
						value={filterLocal.client}
						onChange={onChangeClients}

						sx={{ mb: 4, minWidth: '200px', mr: 5 }}
					>

						{clientsOptions.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.name}
							</MenuItem>
						))}

					</Select> */}

				</Box>

			</Toolbar>
		)
	}



	const columns = useMemo( () => [
		{
			field: 'id',
			headerName: strings.id,
			width: 90
		},
		{
			field: 'name',
			headerName: strings.name,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link component={LinkRouter} to={`/platforms/${params.id}`}>
					{params.value}
				</Link>
			)
		},
		{
			field: 'email',
			headerName: strings.email,
			flex: 1,
			minWidth: 250,
			renderCell: (params) => (
				<Link href={`mailto:${params.value}`}>
					{params.value}
				</Link>
			)
		},{
			field: 'status',
			headerName: strings.status,
			width: 150,
			renderCell: (params) => <Button onClick={() => {

				setItem({ id: params.id, status: params.value })
				setOpenDialog(true)

			}}
				color={params.value === 1 ? 'success' : 'error'} size="small">{params.value === 1 ? strings.enabled : strings.disabled}</Button>
		}
	], []);


	useEffect(() => {

		props.setSubMenu({});

		if (!load ) return;

		const loadClientData = async () => {

			try {
				const response = await axios.get(url + '/clients', {
					params: {
						limit: 100,
						sort: 'name'
					},
					headers: authHeader()
		
				});

				if (response){
					const { data, meta } = response.data;
					
					setClientsOptions([...data]);
		
					if (meta && meta.token) {
						setAuthHeader(meta.token);
					}

				}

			} catch (error) {

				console.log(error);
	
				alert(error.message);
	
				if (error.response && error.response.status && error.response.status === 401) {
	
					sessionStorage.clear();
					return window.location.assign('/');
				}
	
				const { meta } = error.response.data;
				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}
		}
	

		const loadData = async () => {
			
			try {
				const response = await axios.get(url + '/platforms', {
				
					params: {
						limit: 100,
						sort: 'name'
					},
		
					headers: authHeader()
		
				});

				if (response){
					const { data, meta } = response.data;
	
					setMyArray(data)
		
					if (meta && meta.token) {
						setAuthHeader(meta.token);
					}
	
				}

			} catch (error) {
				
				console.log(error);
	
				alert(error.message);
	
				if (error.response && error.response.status && error.response.status === 401) {
	
					sessionStorage.clear();
					return window.location.assign('/');
				}
	
				const { meta } = error.response.data;
				if (meta && meta.token) {
					setAuthHeader(meta.token);
				}
			}
		}

		loadClientData();
		loadData();

		if (sessionStorage.getItem("filter_platform")){
			setFilterLocal(JSON.parse(sessionStorage.getItem("filter_platform")))
		}

		console.count('useEffect');

		setLoad(false);

	}, [load, url, authHeader, setAuthHeader]);

	let rows = myArray ? [...myArray] : [];

	filterLocal.text = filter;

	if (rows.length && filterLocal.text !== ''){
		rows = [...rows].filter((e) => new RegExp(filterLocal.text).test(e.name));	
	}

	if (rows.length && filterLocal.client && Array.isArray(filterLocal.client) && filterLocal.client.length){
		rows = [...rows].filter( 
			e => e.clients && filterLocal.client.includes(e.clients.id) );	
	}

	return (

		<Box sx={{ display: 'flex', height: '90%' }}>

			<Box sx={{ flexGrow: 1 }}>

				<Box sx={{ height: '10%' }}>

					<Toolbar>
						<WebStories sx={{fontSize: 40, mr: 2}} />

						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{strings.platforms}
						</Typography>

						<IconButton title={strings.filter} sx={{ mr: 1 }} color="primary" component="span" onClick={() => setFilterPanel(!filterPanel)}>
							{filterPanel ? <FilterAltOffIcon></FilterAltOffIcon> : <FilterAltIcon />}
						</IconButton>


						<IconButton title={strings.reload} color="primary" component="span" onClick={ () => setLoad(true) } sx={{ mr: 1 }} ><RotateLeftIcon /></IconButton>

						<Button component={LinkRouter} to={`/platforms/0`} startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary">{strings.add}</Button>
					</Toolbar>
				</Box>

				{filterPanel &&
					<Box sx={{ height: '10%' }}>
						<FilterPanelGui/>
					</Box>
				}

				{rows.length > 0 && (

					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={25}
						checkboxSelection
						disableSelectionOnClick
						localeText={getDataGridLocale()}
					/>
				)}

			</Box>

			<ConfirmDialog />
		</Box>
	);
}
