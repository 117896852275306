import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import config from "../../config.js";
import helperHeader from '../../authHeader.js';
import { strings, getDataGridLocale } from '../../strings.js'
import { Link, Skeleton, Toolbar } from "@mui/material";
import { Link as LinkRouter, useParams } from "react-router-dom";

export default function DataGridPlatformLogs(props) {

    const { id = '0' } = useParams();

    const platform = { id }
    const [title, setTitle] = useState(null);

    const [rows, setRows] = useState(undefined);

    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: strings.id,
            width: 80
        },

        {
            field: 'event',
            headerName: strings.event,
            width: 200,
            renderCell: (params) => (
                <>{params.value}</>
            )
        },
        {
            field: 'data',
            headerName: strings.data,
            flex: 1,
            minWidth: 250,
            renderCell: (params) => (
                <>{params.value}</>
            )
        },

        {
            field: 'created_at',
            headerName: strings.createdAt,
            minWidth: 250,
            renderCell: (params) => {
                const d = new Date(params.value);
                return (
                    <>{d.toLocaleString()}</>
                )
            }
        }
    ], []);



    useEffect(() => {

        async function loadData(params) {

            const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;
            const { authHeader, setAuthHeader } = helperHeader;

            try {

                const response = await axios.get(url + '/platforms/' + platform.id, { headers: authHeader() })
                
                if (response) {
                    const { platform, meta } = response.data;
                    setTitle(`Logs: ${platform.name}`);
    
                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }
                }
            }
            catch (error) {
                console.log(error);
                alert(error.message);
    
                if (error.response && error.response.status && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.assign('/');
                }
    
                const { meta } = error.response.data;
                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
            }

            try {

                const { data: response } = await axios.get(url + `/platforms/${platform.id}/logs`, {
                    params: {
                        limit: 100,
                        sort: 'created_at'
                    },

                    headers: authHeader()
                });

                const { data, meta } = response;

                setRows(data);

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }

            } catch (error) {

                console.log(error);

                if (error.response && error.response.status && error.response.status === 401) {

                    sessionStorage.clear();
                    return window.location.assign('/');
                }
            }
        }

        loadData();

        console.count("useEffect");

    }, [platform.id]);

    

    return (

        <>
            <Toolbar sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} >
                    <Link component={LinkRouter} to={`/platforms/${platform.id}`}>
                        {title}
                    </Link>
                </Box>
            </Toolbar>

            <Box sx={{ display: 'flex', height: '90%' }}>

                <Box sx={{ flexGrow: 1 }}>

                    {rows ?
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={25}
                            checkboxSelection
                            disableSelectionOnClick
                            localeText={getDataGridLocale()}
                        />

                        :

                        <>
                            <Skeleton height={42} />
                            <Skeleton animation={false} height={42} />
                            <Skeleton animation="wave" height={42} />
                        </>

                    }

                </Box>
            </Box>

        </>


    )

}