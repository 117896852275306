import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import config from "../../config.js";
import helperHeader from '../../authHeader.js';
import { strings, getDataGridLocale } from '../../strings.js'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, MenuItem, Skeleton, TextField, Toolbar } from "@mui/material";
import ActionAreaCard from "../tools/ActionAreaCard.js";
import { Add, Check, Close, Remove } from "@mui/icons-material";
import { Link as LinkRouter, useParams } from "react-router-dom";

let selectedRows = [];
const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

let rowData = null;

export default function DataGridPlatformRanks(props) {

    const { id='0' } = useParams();

    const {filter} = props;

    const platform = {id}
    const [title, setTitle] = useState(null);

    const [openDialog, setOpenDialog] = useState({
        show: false,
        id: null
    });

    const [rows, setRows] = useState(undefined);
    const [ openConfim , setOpenConfim] = useState(false);


    async function onOpenDialog(id) {

        const { authHeader, setAuthHeader } = helperHeader;

        try {

            if (id !== undefined){
                const response = await axios.get(url + '/ranks/' + id, {
                    params: {},
                    headers: authHeader()
                })
    
                if (response) {
                    const { data, meta } = response.data;
    
                    setOpenDialog({
                        id,
                        show: true,
                        rank: {
                            ...data
                        }
                    })
    
                    if (meta && meta.token) {
                        setAuthHeader(meta.token);
                    }
                }
            }

            else{

                setOpenDialog({
                    id : 0,
                    show: true,
                    rank: null
                })
            }

          
        } catch (error) {
            console.log(error);

            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {

                sessionStorage.clear();
                return window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }

    }

    async function onDelete( e ){
        

        if (selectedRows.length > 0){

            const { authHeader, setAuthHeader } = helperHeader;

            const promises = [];

            selectedRows.forEach(element => {

                promises.push(axios.delete(url + `/ranks/${element}`, {
                    headers: authHeader()
                }));
            });

            Promise.all(promises).then( values => {
                loadData();
            }) 
        }

        setOpenConfim(false)

    }


    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: strings.id,
            width: 80
        },

        {
            field: 'name',
            headerName: strings.name,
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <>
                    <Button title={params.row.description} onClick={e => onOpenDialog(params.id)} > {params.value} </Button>
                </>
            )
        },
        {
            type: 'number',
            field: 'min_points',
            headerName: strings.points,
            minWidth: 120,
            renderCell: (params) => (
                <>{params.value}</>
            )
        },
        {
            field: 'created_at',
            headerName: strings.createdAt,
            minWidth: 250,
            renderCell: (params) => {
                const d = new Date(params.value);
                return (
                    <>{d.toLocaleString()}</>
                )
            }
        }
    ], [onOpenDialog]);


    async function loadData(params) {

        const { authHeader, setAuthHeader } = helperHeader;

        try {

            const response = await axios.get(url + '/platforms/' + id, { headers: authHeader() })
            
            if (response) {
                const { platform, meta } = response.data;
                setTitle(`Ranks: ${platform.name}` );

                if (meta && meta.token) {
                    setAuthHeader(meta.token);
                }
            }
        }
		catch (error) {
            console.log(error);
            alert(error.message);

            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.assign('/');
            }

            const { meta } = error.response.data;
            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }
        }

        try {

            const { data: response } = await axios.get(url + `/platforms/${platform.id}/ranks`, {
                params: {
                    limit: 100,
                    sort: 'created_at'
                },

                headers: authHeader()
            });

            const { data, meta } = response;

            setRows(data);

            if (meta && meta.token) {
                setAuthHeader(meta.token);
            }

        } catch (error) {

            console.log(error);

            if (error.response && error.response.status && error.response.status === 401) {

                sessionStorage.clear();
                return window.location.assign('/');
            }
        }
    }

    

    function ConfirmDialog( props ){

        const {
            title = strings.confirm,
            body = strings.deleteConfirm
        } = props;
    

        return (
           <Dialog
                open={openConfim}
                onClose={(event, reason) => { if (reason && reason === "backdropClick") return; setOpenConfim(false) }}
                aria-labelledby="draggable-dialog-title"
                fullWidth={true}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {body}
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="error" endIcon={<Close />} onClick={ e => setOpenConfim(false)}>{strings.cancel}</Button>
                    <Button variant="contained" color="primary" endIcon={<Check />} onClick={ onDelete }>{strings.delete}</Button>
                </DialogActions>
            </Dialog>
        )
    } 


    function FormDialog(props) {

        const [rank, setRank] = useState(openDialog.rank);

        const languages = [
            {value : 'IT', label : 'Italiano' },
            {value : 'EN', label : 'English' }
        ]


        async function onClickAction(event) {

            const { authHeader, setAuthHeader } = helperHeader;

            try {

                const { id, name, description, min_points } = rank;


                if ( id ){ 

                    const { data } = await axios.patch(url + '/ranks/' + id, {
                        name,
                        description,
                        platform_id : platform.id,
                        min_points
                    }, { headers: authHeader() })


                    setOpenDialog({
                        show: false,
                        id: null,
                        rank: null
                    })

                    loadData();
                }

                else{

                
                    const { rank } = await axios.post(url + '/ranks', {
                        name,
                        description,
                        platform_id : platform.id,
                        min_points

                    }, { headers: authHeader() })


                    setOpenDialog({
                        show: false,
                        id: null,
                        rank: null
                    })

                    loadData();

                }


            } catch (error) {

                alert(error);
            }
        }


        function onCloseAction() {

            setOpenDialog({
                show: false,
                id: null,
                rank: null
            })
        }

        return (

            <Dialog
                open={openDialog.show}
                onClose={(event, reason) => { if (reason && reason === "backdropClick") return; onCloseAction() }}
                aria-labelledby="draggable-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {rank ? `${rank.name} #${openDialog.id}` : strings.addNew}
                </DialogTitle>
                <DialogContent>

                    <Grid container spacing={2} sx={{ mb: 4 }} >

                        <Grid item xs={12} md={8}>

                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    
                                    <TextField

                                        name="name"
                                        required={true}
                                        label={strings.name}
                                        variant="standard"
                                        value={rank ? rank.name : ''}
                                        onChange={e => setRank({
                                            ...rank,
                                            name: e.target.value
                                        })}

                                        sx={{ mb: 4, minWidth: '200px', mr: 5 }}
                                    />

                                    <TextField
                                            id="standard-select-currency"
                                            select
                                            label="Select"
                                            defaultValue="EUR"
                                            helperText="Please select your currency"
                                            variant="standard"
                                            >
                                            {languages.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                            </TextField>
                                </Box>


                                <TextField

                                    name="description"
                                    multiline
                                    rows={4}
                                    label={strings.description}
                                    variant="standard"
                                    value={rank ? rank.description : ''}
                                    onChange={e => setRank({
                                        ...rank,
                                        description: e.target.value
                                    })}

                                    sx={{ mb: 4, minWidth: '200px', mr: 5 }}
                                />

                                <TextField

                                    name="min_points"
                                    label={strings.points}
                                    variant="standard"
                                    value={rank ? rank.min_points : 0}
                                    onChange={e => setRank({
                                        ...rank,
                                        min_points: e.target.value
                                    })}

                                    sx={{ mb: 4, minWidth: '200px', mr: 5 }}
                                />

                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ width: '100%', marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                                {openDialog.id !== 0 && (
                                    <ActionAreaCard object={{ entity: 'ranks', ...openDialog.rank }} />
                                )} 
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" endIcon={<Close />} onClick={onCloseAction}>{strings.cancel}</Button>
                    <Button disabled={rank === null} variant="contained" color="primary" endIcon={<Check />} onClick={onClickAction}>{strings.save}</Button>
                </DialogActions>
            </Dialog>
        )
    }


    useEffect(() => {

        props.setSubMenu({
            sub : 'platform',
            id 
        });

        loadData();
    }, []);



    if (rows && rows.length && filter !== ''){
		rowData = [...rows].filter((e) => new RegExp(filter, 'i').test(e.name));	
	}
    else{
        rowData = rows;
    }

    return (

        <>
            <ConfirmDialog 
                title={strings.confirm} 
                body={strings.confirmDelete}
            />

            <FormDialog />

            <Toolbar sx={{ width: '100%' }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} >
                        <Link component={LinkRouter} to={`/platforms/${platform.id}`}>
                            {title}
                        </Link>
                    </Box>

                    <Box sx={{display: 'flex',justifyContent: 'flex-end',width: '100%'}} >
                        <Button sx={{ mr: 1 }} type='button' onClick={e => onOpenDialog() } endIcon={<Add />} variant="contained" color="primary">{strings.add}</Button>
                        <Button type='button' onClick={e => setOpenConfim(true) } endIcon={<Remove />} variant="contained" color="error">{strings.delete}</Button>
                    </Box>
                </Box>

            </Toolbar>


            <Box sx={{ display: 'flex', height: '90%' }}>

                <Box sx={{ flexGrow: 1 }}>

                    {rowData ?
                        <DataGrid
                            rows={rowData}
                            columns={columns}
                            pageSize={25}
                            checkboxSelection
                            disableSelectionOnClick
                            onSelectionModelChange={ e => {
                                selectedRows = [...e]
                            }}

                            localeText={getDataGridLocale()}
                        />

                        :

                        <>
                            <Skeleton height={42} />
                            <Skeleton animation={false} height={42} />
                            <Skeleton animation="wave" height={42} />
                        </>
                    }

                </Box>
            </Box>
        </>
    )

}