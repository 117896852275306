import { useState, useEffect } from "react"

const useBrowserLanguageDetector = () => {
	const getCurrentLanguage = () => window.navigator.language.slice(0, 2)
	const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage())  

    useEffect(() => {
		window.onlanguagechange = (event) => {
			if (!localStorage.getItem('language')) {
				setCurrentLanguage(getCurrentLanguage())
			}
		}
    }, [])

	return currentLanguage
}

export default useBrowserLanguageDetector
